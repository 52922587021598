import React, { useEffect, useState } from 'react';
import {
    getCandidateAction,
    getProcessAggregationListByCandidateAction,
    updateCandidateAction,
} from '../../../redux/entities/entities.actions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { themeGreen } from '../../../theme/theme';
import {
    resetCandidatePageFieldsForIgnoreValidation,
    setCandidateEditViewActiveTabAction,
    setCandidatePageCandidateToDeleteAction,
} from '../../../redux/candidatePage/candidatePage.actions';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import CandidateProfileDataPanel from './components/CandidateProfileDataPanel';
import CandidateActivityChatPanel from './components/CandidateActivityChatPanel';
import CandidateDocumentPanel from './components/CandidateDocumentPanel';
import { ICandidateForm, initialCandidateFormState } from '../../../definitions/candidatePage.definitions';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../translation/translate.utils';
import { createApiObject } from '../../../utils/candidate.utils';
import { ICandidateLanguage, ICvParseResult, IEntitySkill } from '../../../definitions/entities.definition';
import { registerInfo, resetFormValidation } from '../../../redux/error/error.actions';
import { ClientApi } from '../../../requests/ClientApi';
import { getParseCandidateCvDataRouteConfig } from '../../../requests/routes';
import { ITab } from '../../../definitions/components.definitions';
import { setUiSplitCandidateView } from '../../../redux/ui/ui.actions';
import { Divider } from '@material-ui/core';
import { EIcons } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import CandidateProcessPanel from './components/CandidateProcessPanel';
import { getTabCountLabel } from '../../../utils/ui.utils';

interface IProps {
    id: number;
    tabColor?: string;
}

const CandidateView: React.FC<IProps> = (props) => {
    const tabColor = props.tabColor || themeGreen.palette.primary.main;
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.id;
    const candidate = useSelector((store: IStore) => store.entities.candidates.byId[candidateId]);
    const countProcesses = useSelector((store: IStore) => (store.entities.processAggregation.byCandidate[candidateId] || []).length);
    const [candidateForm, setCandidateForm] = useState<ICandidateForm>(initialCandidateFormState);
    const fieldsIgnoredForValidation = useSelector((store: IStore) => store.candidatePage.formFieldsIgnoredForValidation);
    const [loadFromCv, setLoadFromCv] = useState(false);
    const [loading, setLoading] = useState(false);
    const fieldsToLoadFromCv = [
        'name',
        'firstName',
        'address',
        'zipCode',
        'city',
        'mailAddress',
        'xingUrl',
        'linkedInUrl',
        'mobileNumber',
        'phoneNumber',
        'birthDate',
        'imagePath'
    ];
    const [changedFieldsByCvParser, setChangedFieldsByCvParser] = useState<string[]>([]);
    const [initialForm, setInitialForm] = useState<ICandidateForm>(initialCandidateFormState);
    const [activeTab, setActiveTab] = useState(0);
    const splitCandidateEditView = useSelector((store: IStore) => store.ui.splitCandidateEditView);

    const setSplitView = (split: boolean) => {
        dispatch(setUiSplitCandidateView(split));
    }

    const isDataSaved = () => {
        return JSON.stringify(candidateForm) === JSON.stringify(initialForm);
    }

    const saveDisabled = isDataSaved();

    useEffect(() => {
        if (candidateId > 0) {
            dispatch(resetCandidatePageFieldsForIgnoreValidation());
            dispatch(getCandidateAction(token, candidateId, false));
            dispatch(getProcessAggregationListByCandidateAction(token, candidateId));
            return;
        }
    }, [candidateId]);


    useEffect(() => {
        if (!candidate) {
            return;
        }
        const initialCandidateForm: ICandidateForm = {
            ...candidateForm,
            name: candidate.name,
            firstName: candidate.firstName,
            address: candidate.address,
            freelancer: candidate.freelancer,
            zipCode: candidate.zipCode,
            city: candidate.city,
            salutation: candidate.salutation,
            title: candidate.title,
            mailAddress: candidate.mailAddress,
            xingUrl: candidate.xingUrl,
            linkedInUrl: candidate.linkedInUrl,
            websiteUrl: candidate.websiteUrl,
            furtherUrl: candidate.furtherUrl,
            githubUrl: candidate.githubUrl,
            phoneNumber: candidate.phoneNumber,
            mobileNumber: candidate.mobileNumber,
            birthDate: candidate.birthDate,
            countryId: candidate.country ? candidate.country.id : null,
            salaryUnit: candidate.salaryUnit,
            expectedSalary: candidate.expectedSalary,
            expectedSalaryTo: candidate.expectedSalaryTo,
            homeOfficeDesire: candidate.homeOfficeDesire,
            noticePeriod: candidate.noticePeriod,
            leader: candidate.leader,
            yearsOfWorkExperience: candidate.yearsOfWorkExperience,
            desiredJobDescription: candidate.desiredJobDescription,
            currentJobDescription: candidate.currentJobDescription,
            hasDriverLicense: candidate.hasDriverLicense,
            wishDateOfStart: candidate.wishDateOfStart,
            partTimeFullTime: candidate.partTimeFullTime,
            skills: candidate.skills,
            languages: candidate.languages,
            comment: candidate.comment,
            responsible: candidate.responsible,
            topCandidate: candidate.topCandidate,
            topInCommunication: candidate.topInCommunication,
            moveWillingness: candidate.moveWillingness,
            typus: candidate.typus,
            proudOf: candidate.proudOf,
            thoughtsAboutTeamAndAppreciation: candidate.thoughtsAboutTeamAndAppreciation,
            flexibility: candidate.flexibility,
            freelancerMapUrl: candidate.freelancerMapUrl,
            newsletterEnabled: candidate.newsletterEnabled,
            source: candidate.source,
            followupDate: candidate.followupDate,
            followupComment: candidate.followupComment,
            topInPotential: candidate.topInPotential,
            numberOfEmployers: candidate.numberOfEmployers,
            educationLevel: candidate.educationLevel,
            level: candidate.level
        };
        dispatch(resetFormValidation());
        setChangedFieldsByCvParser([]);
        setCandidateForm(initialCandidateForm);
        setInitialForm(initialCandidateForm);
    }, [candidate])

    const checkIfCvImportMakeSense = () => {
        let fieldInfoMissing = false;
        fieldsToLoadFromCv.forEach((field) => {
            //@ts-ignore
            if (!candidateForm[field]) {
                fieldInfoMissing = true;
            }
        });

        return fieldInfoMissing;
    }

    useEffect(() => {
        if (!loadFromCv) {
            return
        }

        setLoading(true);
        ClientApi.request(getParseCandidateCvDataRouteConfig, {
            token,
            candidateId: candidateId,
        }).then((result: ICvParseResult) => {
            setLoading(false);
            setLoadFromCv(false);

            const changes: Partial<ICandidateForm> = {};
            const changedFields: string[] = [];
            fieldsToLoadFromCv.forEach((field) => {
                //@ts-ignore
                if (!candidateForm[field] && result[field]) {
                    //@ts-ignore
                    changes[field] = result[field];
                    changedFields.push(field);
                }
            });

            //@ts-ignore
            const selectedSkillIds = candidate.skills.map(({ skill }) => skill.id);
            const selectedLanguageIds = candidate.languages.map(({ language }) => language.id);

            const skillsToAdd: IEntitySkill[] = [];
            const languagesToAdd: ICandidateLanguage[] = [];

            result.skills
                .filter((skill) => selectedSkillIds.indexOf(skill.id) === -1)
                .forEach((skill) => {
                    skillsToAdd.push({
                        skill,
                        id: - (skill.id),
                        count: 0,
                        importantSkill: false,
                        addedByParser: true
                    });
                });

            result.languages
                .filter((language) => selectedLanguageIds.indexOf(language.language.id) === -1)
                .forEach((language) => {
                    languagesToAdd.push({
                        id: language.language.id,
                        level: language.level,
                        language: language.language,
                        addedByParser: true
                    });
                });

            if (skillsToAdd.length > 0) {
                changes.skills = [
                    ...candidate.skills,
                    ...skillsToAdd
                ]
            }

            if (languagesToAdd.length > 0) {
                changes.languages = [
                    ...candidate.languages,
                    ...languagesToAdd
                ]
            }

            setChangedFieldsByCvParser(changedFields);
            if (changedFields.length === 0) {
                dispatch(registerInfo('Es konnten keine neue Daten ermittelt werden'));
                return;
            }

            dispatch(registerInfo('Bitte prüfen und speichern. Folgende Informationen wurden gefunden (gekennzeichnet durch dicke Schrift): ' + changedFields.join(', ')));
            setCandidateForm({
                ...candidateForm,
                ...changes
            });

        }).catch(() => {
            setLoading(false);
            setLoadFromCv(false);
        });
    }, [loadFromCv]);

    if (candidateId === 0) {
        return null;
    }

    if (candidateId > 0 && (!candidate || candidateForm.name === undefined)) {
        return <>
            <div className={"flexContainerRow flex"}>
                <CustomBackdropLoadingInsideDiv open={true} />
            </div>
        </>;
    }

    const onSaveProfile = () => {
        const candidate = createApiObject(candidateForm, fieldsIgnoredForValidation, false);
        delete candidate.linkWithSuggestionIds;
        candidate.id = candidateId;
        dispatch(updateCandidateAction(token, candidate));
    }

    const setCandidateFormPartial = (candidateFormChange: Partial<ICandidateForm>) => {
        setCandidateForm({
            ...candidateForm,
            ...candidateFormChange
        });
    }

    const tabs: ITab[] = [];

    if (!splitCandidateEditView) {
        tabs.push({
            label: <div className={"flexContainerRow"}>
                <CustomClickableIcon
                    onClick={() => setSplitView(!splitCandidateEditView)}
                    icon={EIcons.ScreenShare}
                    iconStyle={{color: 'inerhit', transform: 'scaleX(-1)'}}
                    tooltip={"Dokumente links anheften"}
                />
                Dokumente ({candidate.countDocuments})
            </div>,
            component: <>
                <CandidateDocumentPanel
                    candidateId={candidateId}
                    selectedDocumentId={candidate.latestDocumentCvId}
                />
            </>
        });
    }

    tabs.push({
        label: 'Stammdaten',
        component: <>
            <CustomBackdropLoadingInsideDiv open={loading} />
            <CandidateProfileDataPanel
                changedFieldsByCvParser={changedFieldsByCvParser}
                candidate={candidateForm}
                setCandidate={setCandidateFormPartial}
            />
            <CustomDialogActions
                divider
                buttons={[{
                    void: () => dispatch(setCandidatePageCandidateToDeleteAction(candidateId)),
                    label: 'Löschen'
                }, {
                    void: () => setLoadFromCv(true),
                    label: 'Fehlende Daten aus CV laden',
                    hidden: !checkIfCvImportMakeSense()
                }, {
                    void: onSaveProfile,
                    disabled: saveDisabled,
                    tooltip: saveDisabled ? 'Es gibt keine Veränderung' : undefined,
                    label: translate('misc.buttons.save')
                }]}
            />
        </>
    });

    tabs.push({
        label: <>Vorgänge {getTabCountLabel(
            countProcesses,
            0
        )}</>,
        component: <>
            <CandidateProcessPanel candidateId={candidateId} />
        </>
    }, {
        label: 'Historie',
        component: <>
            <CandidateActivityChatPanel candidateId={candidateId} />
        </>
    });

    const getTabPanel = (vertical:boolean) => {
        return <CustomTabContainer
            activeTab={activeTab}
            orientation={vertical ? 'vertical' : 'horizontal'}
            verticalWidth={190}
            color={tabColor}
            onTabChange={(tab) => {
                setActiveTab(tab);
                dispatch(setCandidateEditViewActiveTabAction(tab))
            }}
            tabs={tabs}
        />;
    }

    if (!splitCandidateEditView) {
        return <>
            <div className={"flexContainerRow flex"} style={{width: 900}}>
                {getTabPanel(false)}
            </div>
        </>
    }

    return <>
        <div className={"flexContainerRow flex"} style={{maxWidth: 1400}}>
            <div className={"flexContainerColumn"} style={{maxWidth: 900}}>
                <CandidateDocumentPanel
                    splitView
                    closeSplitView={() => setSplitView(!splitCandidateEditView)}
                    candidateId={candidateId}
                    selectedDocumentId={candidate.latestDocumentCvId}
                />
            </div>

            <Divider orientation={"vertical"} style={{marginLeft:5, marginRight: 5}}/>

            <div className={"flexContainerColumn"} style={{maxWidth: 900}}>
                {getTabPanel(false)}
            </div>
        </div>
    </>;
};

export default React.memo(CandidateView);
