import {
    IGetRecruitingListAction,
    IPostActivityAction,
    IPostRecruitingAction,
    IPostSuggestionAction,
    IPutRecruitingAction,
    IPutSuggestionAcceptAction,
    IPutSuggestionAction,
    IPutSuggestionAndReloadListAction,
    ISetRecruitingPageActiveTabAction,
    ISetRecruitingPageAddSuggestionAction,
    ISetRecruitingPageCreateCongratulationMailAction,
    ISetRecruitingPageDeclineSuggestionAction,
    ISetRecruitingPageDocumentSortingOptionAction,
    ISetRecruitingPageDocumentToDeleteAction,
    ISetRecruitingPageDocumentToEditAction,
    ISetRecruitingPageEditViewActivityListFilterAction,
    ISetRecruitingPageEditViewActivitySortingOptionAction,
    ISetRecruitingPageFastSearchAction,
    ISetRecruitingPageFilterAction,
    ISetRecruitingPageRecruitingListViewFilterAction,
    ISetRecruitingPageRecruitingListViewFilterMenuAction,
    ISetRecruitingPageRecruitingToDeleteAction,
    ISetRecruitingPageSelectCandidateForAssignCandidateToSuggestionViewAction,
    ISetRecruitingPageSortingOptionAction,
    ISetRecruitingPageStartRecruitingAction,
    ISetUiCurrentRouteAction,
    ISetVacancyEditViewActiveTabAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    RECRUITING_PAGE_ADD_SUGGESTION,
    RECRUITING_PAGE_CLOSE_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW,
    RECRUITING_PAGE_CLOSE_CHANGE_RECRUITING_RESPONSIBLE_WINDOW,
    RECRUITING_PAGE_CLOSE_RECRUITING_LIST_VIEW,
    RECRUITING_PAGE_RELOAD_RECRUITING,
    RECRUITING_PAGE_SELECT_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW,
    RECRUITING_PAGE_SET_ACTIVE_TAB,
    RECRUITING_PAGE_SET_ACTIVE_TAB_OF_EDIT_VACANCY_VIEW,
    RECRUITING_PAGE_SET_CONTRACT_RECRUITING,
    RECRUITING_PAGE_SET_CREATE_CONGRATULATION_MAIL,
    RECRUITING_PAGE_SET_DECLINE_RECRUITING,
    RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER,
    RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    RECRUITING_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT,
    RECRUITING_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION,
    RECRUITING_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE,
    RECRUITING_PAGE_SET_FAST_SEARCH,
    RECRUITING_PAGE_SET_FILTER,
    RECRUITING_PAGE_SET_RECRUITING_LIST_RESET_FILTER,
    RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER,
    RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER_MENU,
    RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_OPEN,
    RECRUITING_PAGE_SET_RECRUITING_TO_DELETE,
    RECRUITING_PAGE_SET_SORTING_OPTION,
    RECRUITING_PAGE_SET_SUGGESTION_TO_DECLINE,
    RECRUITING_PAGE_START_RECRUITING,
} from './recruitingPage.actions';
import {
    defaultRecruitingFilter,
    ERecruitingPageTabs,
    IRecruitingPageState,
    ISuggestionFilterMenu,
    preloadedStateRecruitingPage,
} from '../../definitions/recruitingPage.definitions';
import {
    ENTITIES_DELETE_RECRUITING_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_RECRUITING_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_FULFILLED,
    ENTITIES_GET_RECRUITING_PENDING,
    ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED,
    ENTITIES_POST_ACTIVITY_FULFILLED,
    ENTITIES_POST_RECRUITING_FULFILLED,
    ENTITIES_POST_SUGGESTION_FULFILLED,
    ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_ACTIVITY_FULFILLED,
    ENTITIES_PUT_RECRUITING_FULFILLED,
    ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED,
    ENTITIES_PUT_SUGGESTION_FULFILLED,
} from '../entities/entities.actions';
import { VACANCY_PAGE_SET_RELOAD_VACANCY } from '../vacancyPage/vacancyPage.actions';
import { EActivityType, ERecruitingStatus } from '../../definitions/entities.definition';
import { UI_SET_CURRENT_ROUTE } from '../ui/ui.actions';
import { ERoutes } from '../../definitions/app.definitions';

const recruitingPageReducer = (state = preloadedStateRecruitingPage, action: TReducerAction): IRecruitingPageState => {
    switch (action.type) {
        case RECRUITING_PAGE_SET_CONTRACT_RECRUITING: {
            return {
                ...state,
                //@ts-ignore
                contractRecruitingId: action.payload
            }
        }
        case RECRUITING_PAGE_SET_DECLINE_RECRUITING: {
            return {
                ...state,
                //@ts-ignore
                declineRecruitingId: action.payload
            }
        }
        case RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_OPEN: {
            const actionCast = action as ISetRecruitingPageRecruitingListViewFilterAction;

            return {
                ...state,
                recruitingListViewOpen: true,
                recruitingListViewFilter: {
                    ...actionCast.payload,
                    vacancyId: state.recruitingListViewFilter.vacancyId
                },
                recruitingListViewFilterMenu: actionCast.payload as ISuggestionFilterMenu
            }
        }
        case RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER: {
            const actionCast = action as ISetRecruitingPageRecruitingListViewFilterAction;

            return {
                ...state,
                recruitingListViewFilter: actionCast.payload
            }
        }
        case RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER_MENU: {
            const actionCast = action as ISetRecruitingPageRecruitingListViewFilterMenuAction;

            return {
                ...state,
                recruitingListViewFilterMenu: actionCast.payload
            }
        }
        case RECRUITING_PAGE_SET_RECRUITING_LIST_RESET_FILTER: {
            return {
                ...state,
                recruitingListViewFilter: {
                    vacancyId: state.recruitingListViewFilter.vacancyId
                },
                recruitingListViewFilterMenu: {}
            }
        }
        case RECRUITING_PAGE_CLOSE_RECRUITING_LIST_VIEW: {
            return {
                ...state,
                recruitingListViewOpen: false,
                recruitingListViewFilter: {
                    vacancyId: state.recruitingListViewFilter.vacancyId
                },
                recruitingListViewFilterMenu: {}
            }
        }

        case RECRUITING_PAGE_CLOSE_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW: {
            return {
                ...state,
                assignCandidateToSuggestionData: {
                    candidates: [],
                    recruitingSuggestionId: 0
                }
            }
        }

        case RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER: {
            const actionCast = action as ISetRecruitingPageEditViewActivityListFilterAction;
            return {
                ...state,
                activityFilter: {
                    ...state.activityFilter,
                    ...actionCast.payload
                }
            };
        }
        case RECRUITING_PAGE_SELECT_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW: {
            const actionCast = action as ISetRecruitingPageSelectCandidateForAssignCandidateToSuggestionViewAction;
            return {
                ...state,
                assignCandidateToSuggestionData: {
                    ...state.assignCandidateToSuggestionData,
                    selectedCandidate: actionCast.payload
                }
            }
        }
        case UI_SET_CURRENT_ROUTE: {
            const actionCast = action as ISetUiCurrentRouteAction;

            if (actionCast.payload !== ERoutes.Recruitings) {
                console.log("JO");
                return {
                    ...state,
                    filterRecruitingsGlobal: {},
                    filterTabSuggestion: defaultRecruitingFilter,
                    filterTabRecruitingsSuggest: defaultRecruitingFilter,
                    filterTabRecruitingsInterview: defaultRecruitingFilter,
                    filterTabRecruitingsContract: defaultRecruitingFilter,
                    filterTabRecruitingsAbort: defaultRecruitingFilter
                }
            }

            return {
                ...state
            }
        }
        case RECRUITING_PAGE_SET_CREATE_CONGRATULATION_MAIL: {
            const actionCast = action as ISetRecruitingPageCreateCongratulationMailAction;

            return {
                ...state,
                createCongratulationMailForRecruiting: actionCast.payload
            }
        }
        case RECRUITING_PAGE_ADD_SUGGESTION: {
            const actionCast = action as ISetRecruitingPageAddSuggestionAction;

            return {
                ...state,
                addSuggestionView: actionCast.payload
            }
        }
        case RECRUITING_PAGE_START_RECRUITING: {
            const actionCast = action as ISetRecruitingPageStartRecruitingAction;

            return {
                ...state,
                startRecruitingView: actionCast.payload
            }
        }
        case ENTITIES_PUT_RECRUITING_FULFILLED: {
            const actionCast = action as IPutRecruitingAction;

            return {
                ...state,
                contractRecruitingId: 0,
                declineRecruitingId: 0,
                alertChangeRecruitingResponsible: actionCast.payload.alertPossibleResponsibleChange,
                reloadAllRecruitings: true,
                reloadVacancyList: true
            }
        }
        case RECRUITING_PAGE_SET_RECRUITING_TO_DELETE: {
            const actionCast = action as ISetRecruitingPageRecruitingToDeleteAction;

            return {
                ...state,
                recruitingToDelete: actionCast.payload
            }
        }
        case ENTITIES_PUT_SUGGESTION_FULFILLED: {
            const actionCast = action as IPutSuggestionAction;

            if (actionCast.payload.candidatesWithSameName.length === 0) {
                return {
                    ...state,
                }
            }

            return {
                ...state,
                assignCandidateToSuggestionData: {
                    recruitingSuggestionId: actionCast.payload.recruitingSuggestion.id,
                    candidates: actionCast.payload.candidatesWithSameName
                }
            }
        }
        case ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED: {
            const actionCast = action as IPutSuggestionAndReloadListAction;

            if (actionCast.payload.candidatesWithSameName.length === 0) {
                return {
                    ...state,
                    reloadSuggestions: true
                }
            }

            return {
                ...state,
                reloadSuggestions: true,
                assignCandidateToSuggestionData: {
                    recruitingSuggestionId: actionCast.payload.recruitingSuggestion.id,
                    candidates: actionCast.payload.candidatesWithSameName
                }
            }
        }
        case ENTITIES_POST_SUGGESTION_FULFILLED: {
            const actionCast = action as IPostSuggestionAction;

            if (actionCast.payload.candidatesWithSameName.length === 0) {
                return {
                    ...state,
                    reloadVacancyList: true,
                    reloadSuggestedRecruitings: true,
                    addSuggestionView: {open: false}
                }
            }

            return {
                ...state,
                reloadVacancyList: true,
                reloadSuggestedRecruitings: true,
                assignCandidateToSuggestionData: {
                    recruitingSuggestionId: actionCast.payload.recruitingSuggestion.id,
                    candidates: actionCast.payload.candidatesWithSameName
                }
            }
        }
        case ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED: {
            const actionCast = action as IPutSuggestionAcceptAction;

            return {
                ...state,
                alertChangeRecruitingResponsible: actionCast.payload.alertPossibleResponsibleChange,
                reloadVacancyList: true,
                reloadSuggestedRecruitings: true
            }
        }
        case ENTITIES_PUT_ACTIVITY_FULFILLED:
        case ENTITIES_POST_ACTIVITY_FULFILLED: {
            const actionCast = action as IPostActivityAction;

            if (actionCast.payload.type === EActivityType.interview) {
                return {
                    ...state,
                    reloadInterviewRecruitings: true,
                    reloadSuggestedRecruitings: true
                }
            }

            return state;
        }
        case ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED: {
            return {
                ...state,
                reloadVacancyList: false
            }
        }
        case ENTITIES_DELETE_RECRUITING_FULFILLED: {
            return {
                ...state,
                reloadVacancyList: true,
            }
        }

        case VACANCY_PAGE_SET_RELOAD_VACANCY: {
            return {
                ...state,
                reloadVacancyList: true
            }
        }
        case ENTITIES_POST_RECRUITING_FULFILLED: {
            const actionCast = action as IPostRecruitingAction;

            return {
                ...state,
                alertChangeRecruitingResponsible: actionCast.payload.alertPossibleResponsibleChange,
                reloadSuggestedRecruitings: true,
                reloadVacancyList: true,
            }
        }
        case RECRUITING_PAGE_CLOSE_CHANGE_RECRUITING_RESPONSIBLE_WINDOW: {
            return {
                ...state,
                alertChangeRecruitingResponsible: undefined
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_FULFILLED: {
            const actionCast = action as IGetRecruitingListAction;
            return {
                ...state,
                reloadSuggestedRecruitings: false,
                reloadInterviewRecruitings: false,
                reloadAllRecruitings: false,
                recruitingsSuggestInitialLoaded: actionCast.meta?.status === ERecruitingStatus.STATUS_SUGGEST || state.recruitingsSuggestInitialLoaded,
                recruitingsInterviewInitialLoaded: actionCast.meta?.status === ERecruitingStatus.STATUS_INTERVIEW || state.recruitingsInterviewInitialLoaded,
                recruitingsContractInitialLoaded: actionCast.meta?.status === ERecruitingStatus.STATUS_CONTRACT || state.recruitingsContractInitialLoaded,
                recruitingsAbortInitialLoaded: actionCast.meta?.status === ERecruitingStatus.STATUS_ABORT || state.recruitingsAbortInitialLoaded,
            }
        }
        case RECRUITING_PAGE_SET_SUGGESTION_TO_DECLINE: {
            const actionCast = action as ISetRecruitingPageDeclineSuggestionAction;
            return {
                ...state,
                suggestionToDecline: actionCast.payload,
            };
        }

        case ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED: {
            return {
                ...state,
                suggestionsOfVacancyInitialLoaded: true
            };
        }

        case ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED: {
            return {
                ...state,
                recruitingsOfVacancyInitialLoaded: true
            };
        }

        case ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED: {
            return {
                ...state,
                applicationsOfVacancyInitialLoaded: true
            };
        }

        case RECRUITING_PAGE_SET_ACTIVE_TAB: {
            const actionCast = action as ISetRecruitingPageActiveTabAction;

            return {
                ...state,
                activeTab: actionCast.payload
            }
        }
        case RECRUITING_PAGE_SET_ACTIVE_TAB_OF_EDIT_VACANCY_VIEW: {
            const actionCast = action as ISetVacancyEditViewActiveTabAction;

            return {
                ...state,
                activeTabVacancyEditView: actionCast.payload
            }
        }
        case RECRUITING_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetRecruitingPageSortingOptionAction;
            let sortingKey = '';

            switch (state.activeTab) {
                case ERecruitingPageTabs.tabRecruitingsWithStatusSuggest:
                    sortingKey = 'sortingTabRecruitingsSuggest';
                    break;
                case ERecruitingPageTabs.tabRecruitingsWithStatusInterview:
                    sortingKey = 'sortingTabRecruitingsInterview';
                    break;
                case ERecruitingPageTabs.tabRecruitingsWithStatusContract:
                    sortingKey = 'sortingTabRecruitingsContract';
                    break;
                case ERecruitingPageTabs.tabRecruitingsWithStatusAbort:
                    sortingKey = 'sortingTabRecruitingsAbort';
                    break;
            }

            return {
                ...state,
                [sortingKey]: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case RECRUITING_PAGE_SET_FILTER: {
            const actionCast = action as ISetRecruitingPageFilterAction;

            if (actionCast.payload.useGlobal) {
                return {
                    ...state,
                    filterRecruitingsGlobal: {
                        ...state.filterRecruitingsGlobal,
                        ...actionCast.payload.filter
                    },
                };
            }
            let filterKey = '';
            switch (state.activeTab) {
                case ERecruitingPageTabs.tabRecruitingsWithStatusSuggest:
                    filterKey = 'filterTabRecruitingsSuggest';
                    break;
                case ERecruitingPageTabs.tabRecruitingsWithStatusInterview:
                    filterKey = 'filterTabRecruitingsInterview';
                    break;
                case ERecruitingPageTabs.tabRecruitingsWithStatusContract:
                    filterKey = 'filterTabRecruitingsContract';
                    break;
                case ERecruitingPageTabs.tabRecruitingsWithStatusAbort:
                    filterKey = 'filterTabRecruitingsAbort';
                    break;
            }

            return {
                ...state,
                [filterKey]: actionCast.payload.filter,
            };
        }
        case RECRUITING_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetRecruitingPageFastSearchAction;

            if (state.activeTab === ERecruitingPageTabs.tabVacancies) {
                return {
                    ...state,
                    filterTabSuggestion: {
                        ...state.filterTabSuggestion,
                        fastSearch: actionCast.payload,
                    },
                };
            }

            return {
                ...state,
                filterRecruitingsGlobal: {
                    ...state.filterRecruitingsGlobal,
                    fastSearch: actionCast.payload,
                },
            };
        }

        /**
         * ACTIVITY
         */
        case RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION: {
            const actionCast = action as ISetRecruitingPageEditViewActivitySortingOptionAction;
            return {
                ...state,
                activitySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }

        /**
         * DATENSATZ ZUM BEARBEITEN LADEN JA/NEIN
         */
        case ENTITIES_GET_RECRUITING_PENDING: {
            return {
                ...state,
                editViewLoading: true,
            };
        }
        case RECRUITING_PAGE_RELOAD_RECRUITING: {
            return {
                ...state,
                reloadRecruiting: true,
                reloadAllRecruitings: true
            }
        }
        case ENTITIES_GET_RECRUITING_FULFILLED: {
            return {
                ...state,
                reloadRecruiting: false,
                editViewLoading: false,
            };
        }

        /**
         * DOCUMENTS
         */
        case RECRUITING_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION: {
            const actionCast = action as ISetRecruitingPageDocumentSortingOptionAction;
            return {
                ...state,
                documentSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case RECRUITING_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT: {
            const actionCast = action as ISetRecruitingPageDocumentToEditAction;
            return {
                ...state,
                currentDocumentId: actionCast.payload
            };
        }
        case RECRUITING_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE: {
            const actionCast = action as ISetRecruitingPageDocumentToDeleteAction;
            return {
                ...state,
                documentToDelete: actionCast.payload,
            };
        }
        default:
            return state;
    }
};

export default recruitingPageReducer;
