import React from 'react';
import { ECandidateSource } from '../../definitions/entities.definition';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { Tooltip } from '@material-ui/core';

interface IProps {
    source: ECandidateSource;
    size? : 'small'|'medium';
}

const CandidateSourceRenderer: React.FC<IProps> = (props) => {
    const size = props.size || 'small';
    const width = size === 'small' ? 22 : 45;
    const getIcon = () => {
        switch (props.source) {
            case ECandidateSource.recommendation:
                return <Tooltip title={"Empfehlung"}>{IconFactory.getIconComponent(EIcons.ThumbUp)}</Tooltip>;
            case ECandidateSource.brainzShare:
                return IconFactory.getIconComponent(EIcons.Share);
            case ECandidateSource.brainz:
                return <img width={width} src={"/brain.png"} title={"Quelle: Brainz"} />;
            case ECandidateSource.freeLancerMap:
                return <img width={width} src={"/freelancer_map.jpg"} title={"Quelle: Freelancer Map"} />;
            case ECandidateSource.homepage:
                return <img width={width} src={"/peagle.png"} title={"Quelle: Homepage"} />;
            case ECandidateSource.indeed:
                return <img width={width} src={"/indeed.png"} title={"Quelle: Indeed"} />;
            case ECandidateSource.joyn:
                return <img width={width} src={"/join.png"} title={"Quelle: Join"} />;
            case ECandidateSource.linkedIn:
                return <img width={width} src={"/linkedIn.png"} title={"Quelle: Linked In"} />;
            case ECandidateSource.jobcenter:
                return <img width={width} src={"/arbeitsagentur.png"} title={"Quelle: Arbeitsagentur"} />;
            case ECandidateSource.xing:
                return <img width={width} src={"/xing.png"} title={"Quelle: Xing"} />;
            case ECandidateSource.github:
                return <img width={width} src={"/github.png"} title={"Quelle: Github"} />;
            case ECandidateSource.further:
                return <>Sons.</>;
        }

        return null;
    }

    return <>
        <div className={"flexContainerColumn"}>
            {getIcon()}
        </div>
    </>
}

export default React.memo(CandidateSourceRenderer);
