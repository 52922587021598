import {
    EEntityView,
    ERightDrawerViews,
    IEditViewState,
    IPinnedViewState,
    IUiState,
    preloadedStateUi,
} from '../../definitions/ui.definitions';
import {
    IActorLoginAction,
    IActorRefreshTokenAction,
    IGetCompanyListAction,
    IGetNotifyUnreadCountAction,
    IInitPinnedViewsAction,
    IPostCandidateAction,
    IPostCompanyAction,
    IRemovePinnedViewAction,
    ISetActivityAddDataAction,
    ISetActivityToDeleteAction,
    ISetActivityToEditAction,
    ISetClickedRowAction,
    ISetCloseViewAction,
    ISetCompanyContactAddDataAction,
    ISetCompanyLocationAddDataAction,
    ISetCompanyLocationToEditAction,
    ISetEditViewAction,
    ISetExpandCollapseApplicationListItemsAction,
    ISetExpandCollapseProcessListItemsAction,
    ISetExpandCollapseRecruitingListItemsAction,
    ISetExpandCollapseSuggestionListItemsAction,
    ISetFilterPanelClosed,
    ISetFilterPanelOpen,
    ISetForceExitFastEditAction,
    ISetPinViewAction,
    ISetRightDrawerCloseAction,
    ISetRightDrawerOpenAction,
    ISetSearchCandidateAction,
    ISetUIActivityListViewFilterAction,
    ISetUIActivityListViewFilterMenuAction,
    ISetUIActivityListViewSortingOptionAction,
    ISetUiEditCellAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    UI_CLOSE_ACTIVITY_LIST_VIEW,
    UI_CLOSE_RIGHT_DRAWER_VIEW,
    UI_CLOSE_TOP_DRAWER,
    UI_INIT_PINNED_VIEWS,
    UI_REMOVE_PINNED_VIEW,
    UI_SET_ACTIVITY_ADD_DATA,
    UI_SET_ACTIVITY_LIST_RESET_FILTER,
    UI_SET_ACTIVITY_LIST_VIEW_FILTER,
    UI_SET_ACTIVITY_LIST_VIEW_FILTER_MENU,
    UI_SET_ACTIVITY_LIST_VIEW_OPEN,
    UI_SET_ACTIVITY_LIST_VIEW_SORTING_OPTION,
    UI_SET_ACTIVITY_TO_DELETE,
    UI_SET_ACTIVITY_TO_EDIT,
    UI_SET_CLICKED_ROW,
    UI_SET_CLOSE_CURRENT_EDIT_VIEW,
    UI_SET_CLOSE_VIEW,
    UI_SET_COMPANY_CONTACT_ADD_DATA,
    UI_SET_COMPANY_LOCATION_ADD_DATA,
    UI_SET_COMPANY_LOCATION_TO_EDIT,
    UI_SET_CONGRATULATION_VIEW_OPEN,
    UI_SET_CURRENT_ROUTE,
    UI_SET_EDIT_CELL,
    UI_SET_EDIT_VIEW,
    UI_SET_ENCOURAGEMENT_VIEW_OPEN,
    UI_SET_ENTITY_TO_CREATE_DIALOG,
    UI_SET_EXPAND_COLLAPSE_APPLICATION_LIST_ITEMS,
    UI_SET_EXPAND_COLLAPSE_PROCESS_LIST_ITEMS,
    UI_SET_EXPAND_COLLAPSE_RECRUITING_LIST_ITEMS,
    UI_SET_EXPAND_COLLAPSE_SUGGESTION_LIST_ITEMS,
    UI_SET_FILTER_PANEL_CLOSED,
    UI_SET_FILTER_PANEL_OPEN,
    UI_SET_FORCE_EXIT_FAST_EDIT,
    UI_SET_LOGOUT_DIALOG_OPEN,
    UI_SET_LOGOUT_EXPIRE_DIALOG,
    UI_SET_PIN_VIEW,
    UI_SET_RENEW_PASSWORD,
    UI_SET_RIGHT_DRAWER_VIEW_OPEN,
    UI_SET_SEARCH_CANDIDATE_VIEW,
    UI_SET_SEARCH_VACANCY_VIEW,
    UI_SET_SHOW_ONLY_MINE,
    UI_SET_SPLIT_CANDIDATE_VIEW,
    UI_TOGGLE_TOP_DRAWER,
} from './ui.actions';
import {
    ACTOR_CHANGE_PASSWORD_FULFILLED,
    ACTOR_LOGIN_FULFILLED,
    ACTOR_LOGIN_PENDING,
    ACTOR_LOGIN_REJECTED,
    ACTOR_REFRESH_TOKEN_FULFILLED,
} from '../actor/actor.actions';
import {
    ENTITIES_DELETE_ACTIVITY_FULFILLED,
    ENTITIES_GET_ACTIVITY_CHAT_LIST_FULFILLED,
    ENTITIES_GET_ACTIVITY_CHAT_LIST_PENDING,
    ENTITIES_GET_ACTIVITY_LIST_FULFILLED,
    ENTITIES_GET_ACTIVITY_LIST_PENDING,
    ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_PENDING,
    ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_REJECTED,
    ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_PENDING,
    ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_REJECTED,
    ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_PENDING,
    ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_REJECTED,
    ENTITIES_GET_APPLICATION_LIST_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_PENDING,
    ENTITIES_GET_APPLICATION_LIST_REJECTED,
    ENTITIES_GET_ARCHIVED_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_ARCHIVED_VACANCY_LIST_PENDING,
    ENTITIES_GET_ARCHIVED_VACANCY_LIST_REJECTED,
    ENTITIES_GET_BRANCH_LIST_FULFILLED,
    ENTITIES_GET_CANDIDATE_LIST_APPEND_FULFILLED,
    ENTITIES_GET_CANDIDATE_LIST_APPEND_PENDING,
    ENTITIES_GET_CANDIDATE_LIST_FULFILLED,
    ENTITIES_GET_CANDIDATE_LIST_PENDING,
    ENTITIES_GET_CANDIDATE_LIST_REJECTED,
    ENTITIES_GET_CANDIDATES_CREATED_NEW_FULFILLED,
    ENTITIES_GET_CANDIDATES_CREATED_NEW_PENDING,
    ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_FULFILLED,
    ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_PENDING,
    ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_REJECTED,
    ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_FULFILLED,
    ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_PENDING,
    ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_REJECTED,
    ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_FULFILLED,
    ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_PENDING,
    ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_FULFILLED,
    ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_PENDING,
    ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_PENDING,
    ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_REJECTED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_PENDING,
    ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_PENDING,
    ENTITIES_GET_COMPANY_LIST_FULFILLED,
    ENTITIES_GET_COMPANY_LIST_PENDING,
    ENTITIES_GET_COMPANY_LIST_REJECTED,
    ENTITIES_GET_COMPANY_LOCATION_LIST_FULFILLED,
    ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_FULFILLED,
    ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_PENDING,
    ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_REJECTED,
    ENTITIES_GET_DOCUMENTS_FOR_COMPANY_FULFILLED,
    ENTITIES_GET_DOCUMENTS_FOR_COMPANY_PENDING,
    ENTITIES_GET_DOCUMENTS_FOR_COMPANY_REJECTED,
    ENTITIES_GET_EMPLOYEE_LIST_FULFILLED,
    ENTITIES_GET_EMPLOYEE_LIST_PENDING,
    ENTITIES_GET_EMPLOYEE_LIST_REJECTED,
    ENTITIES_GET_INVOICE_LIST_FULFILLED,
    ENTITIES_GET_INVOICE_LIST_OF_COMPANY_FULFILLED,
    ENTITIES_GET_INVOICE_LIST_OF_COMPANY_PENDING,
    ENTITIES_GET_INVOICE_LIST_OF_COMPANY_REJECTED,
    ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_FULFILLED,
    ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_PENDING,
    ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_REJECTED,
    ENTITIES_GET_INVOICE_LIST_PENDING,
    ENTITIES_GET_INVOICE_LIST_REJECTED,
    ENTITIES_GET_JOB_TEMPLATE_LIST_FULFILLED,
    ENTITIES_GET_JOB_TEMPLATE_LIST_PENDING,
    ENTITIES_GET_JOB_TEMPLATE_LIST_REJECTED,
    ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_FULFILLED,
    ENTITIES_GET_JOB_TITLE_LIST_FULFILLED,
    ENTITIES_GET_JOB_TITLE_LIST_PENDING,
    ENTITIES_GET_JOB_TITLE_LIST_REJECTED,
    ENTITIES_GET_LOCATIONS_FOR_COMPANY_FULFILLED,
    ENTITIES_GET_LOCATIONS_FOR_COMPANY_PENDING,
    ENTITIES_GET_LOCATIONS_FOR_COMPANY_REJECTED,
    ENTITIES_GET_MAIL_LIST_BY_FOLDER_FULFILLED,
    ENTITIES_GET_MAIL_LIST_BY_FOLDER_PENDING,
    ENTITIES_GET_MAIL_LIST_BY_FOLDER_REJECTED,
    ENTITIES_GET_MAIL_LIST_FULFILLED,
    ENTITIES_GET_MAIL_LIST_PENDING,
    ENTITIES_GET_MAIL_LIST_REJECTED,
    ENTITIES_GET_MAILBOX_FOLDER_TREE_FULFILLED,
    ENTITIES_GET_MAILBOX_FOLDER_TREE_PENDING,
    ENTITIES_GET_MAILBOX_FOLDER_TREE_REJECTED,
    ENTITIES_GET_NEWS_LIST_FULFILLED,
    ENTITIES_GET_NEWS_LIST_PENDING,
    ENTITIES_GET_NEWS_LIST_REJECTED,
    ENTITIES_GET_NOTIFY_UNREAD_COUNT_FULFILLED,
    ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_OPEN_VACANCY_LIST_PENDING,
    ENTITIES_GET_OPEN_VACANCY_LIST_REJECTED,
    ENTITIES_GET_PROCESS_AGGREGATION_LIST_FULFILLED,
    ENTITIES_GET_PROCESS_AGGREGATION_LIST_PENDING,
    ENTITIES_GET_PROCESS_AGGREGATION_LIST_REJECTED,
    ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_PENDING,
    ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_REJECTED, ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_PENDING,
    ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_REJECTED,
    ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_PENDING,
    ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_REJECTED,
    ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_PENDING,
    ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_REJECTED,
    ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_PENDING,
    ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_REJECTED,
    ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_PENDING,
    ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_REJECTED,
    ENTITIES_GET_RECRUITING_LIST_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_PENDING,
    ENTITIES_GET_RECRUITING_LIST_PENDING,
    ENTITIES_GET_RECRUITING_LIST_REJECTED,
    ENTITIES_GET_SKILL_LIST_FULFILLED,
    ENTITIES_GET_SKILL_TREE_FULFILLED,
    ENTITIES_GET_SKILL_TREE_PENDING,
    ENTITIES_GET_SKILL_TREE_REJECTED,
    ENTITIES_GET_SUCCESS_ACTIVITY_LIST_FULFILLED,
    ENTITIES_GET_SUCCESS_ACTIVITY_LIST_PENDING,
    ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_PENDING,
    ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_REJECTED,
    ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_PENDING,
    ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_REJECTED,
    ENTITIES_GET_SUGGESTION_LIST_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_PENDING,
    ENTITIES_GET_SUGGESTION_LIST_REJECTED,
    ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_PENDING,
    ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_REJECTED,
    ENTITIES_GET_TODO_LIST_FULFILLED,
    ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED,
    ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_PENDING,
    ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_REJECTED,
    ENTITIES_GET_TODO_LIST_PENDING,
    ENTITIES_GET_TODO_LIST_REJECTED,
    ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_FULFILLED,
    ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_PENDING,
    ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_REJECTED,
    ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_FULFILLED,
    ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_PENDING,
    ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_REJECTED,
    ENTITIES_GET_VACANCY_LIST_OF_COMPANY_FULFILLED,
    ENTITIES_GET_VACANCY_LIST_OF_COMPANY_PENDING,
    ENTITIES_GET_VACANCY_LIST_OF_COMPANY_REJECTED,
    ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_FULFILLED,
    ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_PENDING,
    ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_REJECTED,
    ENTITIES_GET_WEB_VACANCY_LIST_PENDING,
    ENTITIES_GET_WEB_VACANCY_LIST_REJECTED,
    ENTITIES_POST_ACTIVITY_FULFILLED,
    ENTITIES_POST_ACTIVITY_PENDING,
    ENTITIES_POST_ACTIVITY_REJECTED,
    ENTITIES_POST_APPLICATION_FULFILLED,
    ENTITIES_POST_CANDIDATE_DOCUMENT_FULFILLED,
    ENTITIES_POST_CANDIDATE_DOCUMENT_PENDING,
    ENTITIES_POST_CANDIDATE_DOCUMENT_REJECTED,
    ENTITIES_POST_CANDIDATE_FULFILLED,
    ENTITIES_POST_CANDIDATE_PENDING,
    ENTITIES_POST_CANDIDATE_REJECTED,
    ENTITIES_POST_COMPANY_CONTACT_FULFILLED,
    ENTITIES_POST_COMPANY_CONTACT_PENDING,
    ENTITIES_POST_COMPANY_CONTACT_REJECTED,
    ENTITIES_POST_COMPANY_FULFILLED,
    ENTITIES_POST_COMPANY_LOCATION_FULFILLED,
    ENTITIES_POST_COMPANY_LOCATION_PENDING,
    ENTITIES_POST_COMPANY_LOCATION_REJECTED,
    ENTITIES_POST_COMPANY_PENDING,
    ENTITIES_POST_COMPANY_REJECTED,
    ENTITIES_POST_EMPLOYEE_FULFILLED,
    ENTITIES_POST_EMPLOYEE_PENDING,
    ENTITIES_POST_EMPLOYEE_REJECTED,
    ENTITIES_POST_INVOICE_FULFILLED,
    ENTITIES_POST_INVOICE_PENDING,
    ENTITIES_POST_INVOICE_REJECTED,
    ENTITIES_POST_JOB_TEMPLATE_FULFILLED,
    ENTITIES_POST_JOB_TEMPLATE_PENDING,
    ENTITIES_POST_JOB_TEMPLATE_REJECTED,
    ENTITIES_POST_RECRUITING_DOCUMENT_FULFILLED,
    ENTITIES_POST_RECRUITING_DOCUMENT_PENDING,
    ENTITIES_POST_RECRUITING_DOCUMENT_REJECTED,
    ENTITIES_POST_RECRUITING_FULFILLED,
    ENTITIES_POST_RECRUITING_PENDING,
    ENTITIES_POST_RECRUITING_REJECTED,
    ENTITIES_POST_SKILL_FULFILLED,
    ENTITIES_POST_SKILL_PENDING,
    ENTITIES_POST_SKILL_REJECTED,
    ENTITIES_POST_SUGGESTION_ACTIVITY_FULFILLED,
    ENTITIES_POST_SUGGESTION_FULFILLED,
    ENTITIES_POST_SUGGESTION_PENDING,
    ENTITIES_POST_SUGGESTION_REJECTED,
    ENTITIES_POST_TODO_FULFILLED,
    ENTITIES_POST_TODO_PENDING,
    ENTITIES_POST_TODO_REJECTED,
    ENTITIES_POST_VACANCY_FULFILLED,
    ENTITIES_POST_VACANCY_PENDING,
    ENTITIES_POST_VACANCY_REJECTED,
    ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_ACCEPT_SUGGESTION_PENDING,
    ENTITIES_PUT_ACCEPT_SUGGESTION_REJECTED,
    ENTITIES_PUT_ACTIVITY_FULFILLED,
    ENTITIES_PUT_ACTIVITY_PENDING,
    ENTITIES_PUT_ACTIVITY_REJECTED,
    ENTITIES_PUT_CANDIDATE_DOCUMENT_FULFILLED,
    ENTITIES_PUT_CANDIDATE_DOCUMENT_PENDING,
    ENTITIES_PUT_CANDIDATE_DOCUMENT_REJECTED,
    ENTITIES_PUT_CANDIDATE_FULFILLED,
    ENTITIES_PUT_CANDIDATE_PENDING,
    ENTITIES_PUT_CANDIDATE_REJECTED,
    ENTITIES_PUT_COMPANY_CONTACT_FULFILLED,
    ENTITIES_PUT_COMPANY_CONTACT_PENDING,
    ENTITIES_PUT_COMPANY_CONTACT_REJECTED,
    ENTITIES_PUT_COMPANY_FULFILLED,
    ENTITIES_PUT_COMPANY_LOCATION_FULFILLED,
    ENTITIES_PUT_COMPANY_LOCATION_PENDING,
    ENTITIES_PUT_COMPANY_LOCATION_REJECTED,
    ENTITIES_PUT_COMPANY_PENDING,
    ENTITIES_PUT_COMPANY_REJECTED,
    ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED,
    ENTITIES_PUT_DECLINE_SUGGESTION_PENDING,
    ENTITIES_PUT_DECLINE_SUGGESTION_REJECTED,
    ENTITIES_PUT_EMPLOYEE_FULFILLED,
    ENTITIES_PUT_EMPLOYEE_PENDING,
    ENTITIES_PUT_EMPLOYEE_REJECTED,
    ENTITIES_PUT_INVOICE_FULFILLED,
    ENTITIES_PUT_INVOICE_PENDING,
    ENTITIES_PUT_INVOICE_REJECTED,
    ENTITIES_PUT_JOB_TEMPLATE_FULFILLED,
    ENTITIES_PUT_JOB_TEMPLATE_PENDING,
    ENTITIES_PUT_JOB_TEMPLATE_REJECTED,
    ENTITIES_PUT_RECRUITING_DOCUMENT_FULFILLED,
    ENTITIES_PUT_RECRUITING_DOCUMENT_PENDING,
    ENTITIES_PUT_RECRUITING_DOCUMENT_REJECTED,
    ENTITIES_PUT_RECRUITING_FULFILLED,
    ENTITIES_PUT_RECRUITING_PENDING,
    ENTITIES_PUT_RECRUITING_REJECTED,
    ENTITIES_PUT_REOPEN_SUGGESTION_FULFILLED,
    ENTITIES_PUT_REOPEN_SUGGESTION_PENDING,
    ENTITIES_PUT_REOPEN_SUGGESTION_REJECTED,
    ENTITIES_PUT_SKILL_FULFILLED,
    ENTITIES_PUT_SKILL_PENDING,
    ENTITIES_PUT_SKILL_REJECTED,
    ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED,
    ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_PENDING,
    ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_REJECTED,
    ENTITIES_PUT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_SUGGESTION_PENDING,
    ENTITIES_PUT_SUGGESTION_REJECTED,
    ENTITIES_PUT_TODO_FULFILLED,
    ENTITIES_PUT_TODO_PENDING,
    ENTITIES_PUT_TODO_REJECTED,
    ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_FULFILLED,
    ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_PENDING,
    ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_REJECTED,
    ENTITIES_PUT_VACANCY_FULFILLED,
    ENTITIES_PUT_VACANCY_PENDING,
    ENTITIES_PUT_VACANCY_REJECTED,
    ENTITIES_PUT_WEB_VACANCY_FULFILLED,
    ENTITIES_PUT_WEB_VACANCY_PENDING,
    ENTITIES_PUT_WEB_VACANCY_REJECTED,
    ENTITIES_SET_MAIL_READ_FULFILLED,
    ENTITIES_SET_NEWS_READ_TIMESTAMP_FULFILLED,
    ENTITIES_SET_TODOS_READ_TIMESTAMP_FULFILLED,
} from '../entities/entities.actions';
import { DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_FULFILLED } from '../dashboardPage/dashboardPage.actions';
import { IActivityFilterMenu } from '../../definitions/activityPage.definitions';
import { checkViewIsEqual } from '../../components/CustomInput/CustomDatePicker';
import jwt_decode from 'jwt-decode';
import { IJwtData } from '../../definitions/app.definitions';

const uiReducer = (state = preloadedStateUi, action: TReducerAction): IUiState => {
    const setEditView = (addView: IEditViewState) => {
        const parentViews = [...state.openViews].filter((parentView) => {
            return parentView.view !== addView.view;
        });

        return {
            ...state,
            openViews: [
                addView,
                ...parentViews
            ],
            rightDrawerView: undefined
        }
    }

    switch (action.type) {
        case UI_SET_EXPAND_COLLAPSE_SUGGESTION_LIST_ITEMS: {
            const actionCast = action as ISetExpandCollapseSuggestionListItemsAction;

            return {
                ...state,
                expandOrCollapseSuggestionListItems: actionCast.payload
            }
        }

        case UI_SET_EXPAND_COLLAPSE_RECRUITING_LIST_ITEMS: {
            const actionCast = action as ISetExpandCollapseRecruitingListItemsAction;

            return {
                ...state,
                expandOrCollapseRecruitingListItems: actionCast.payload
            }
        }

        case UI_SET_EXPAND_COLLAPSE_APPLICATION_LIST_ITEMS: {
            const actionCast = action as ISetExpandCollapseApplicationListItemsAction;

            return {
                ...state,
                expandOrCollapseApplicationListItems: actionCast.payload
            }
        }

        case UI_SET_EXPAND_COLLAPSE_PROCESS_LIST_ITEMS: {
            const actionCast = action as ISetExpandCollapseProcessListItemsAction;

            return {
                ...state,
                expandOrCollapseProcessListItems: actionCast.payload
            }
        }
        case UI_SET_CLOSE_CURRENT_EDIT_VIEW: {
            return {
                ...state,
                openViews: [
                    ...state.openViews
                ].slice(1)
            }
        }
        case UI_REMOVE_PINNED_VIEW: {
            const actionCast = action as IRemovePinnedViewAction;

            const removeViewFromOpenViewList = [...state.pinnedViews].filter((view) => {
                return !checkViewIsEqual(view, actionCast.payload);
            });

            return {
                ...state,
                pinnedViews: removeViewFromOpenViewList
            }
        }
        case UI_SET_CLOSE_VIEW: {
            const actionCast = action as ISetCloseViewAction;

            const removeViewFromOpenViewList = [...state.openViews].filter((view) => {
                return !checkViewIsEqual(view, actionCast.payload);
            });

            return {
                ...state,
                openViews: removeViewFromOpenViewList
            }
        }
        case UI_SET_SEARCH_CANDIDATE_VIEW: {
            const actionCast = action as ISetSearchCandidateAction;

            return {
                ...state,
                searchForVacancyId: actionCast.payload.vacancyId || 0,
                searchForWebVacancyId: actionCast.payload.webVacancyId || 0
            };
        }
        case UI_SET_SEARCH_VACANCY_VIEW: {
            return {
                ...state,
                searchForCandidateId: action.payload
            };
        }
        case UI_SET_EDIT_VIEW: {
            const actionCast = action as ISetEditViewAction;
            return setEditView(actionCast.payload);
        }
        case UI_INIT_PINNED_VIEWS: {
            const actionCast = action as IInitPinnedViewsAction;

            return {
                ...state,
                pinnedViews: actionCast.payload
            }
        }
        case UI_SET_PIN_VIEW: {
            const actionCast = action as ISetPinViewAction;

            //check view already saved in pined views
            const redundantPinnedElements = [...state.pinnedViews].filter((view) => {
                return checkViewIsEqual(view, actionCast.payload);
            });

            if (redundantPinnedElements.length > 0) {
                return {
                    ...state
                };
            }

            //find open edit view
            let addMinimizeView: null|IPinnedViewState = null;
            [...state.openViews].forEach((view) => {
                if (checkViewIsEqual(view, actionCast.payload)) {
                    addMinimizeView = {
                        ...view,
                        title: actionCast.payload.title
                    };
                }
            });

            if (addMinimizeView === null) {
                return {
                    ...state
                };
            }

            const pinnedViewRemovedFromOpenViews = [...state.openViews].filter((view) => {
                return !checkViewIsEqual(view, actionCast.payload);
            });

            return {
                ...state,
                openViews: pinnedViewRemovedFromOpenViews,
                pinnedViews: [
                    addMinimizeView,
                    ...state.pinnedViews
                ]
            }
        }
        case UI_SET_ACTIVITY_LIST_VIEW_OPEN: {
            const actionCast = action as ISetUIActivityListViewFilterAction;

            return {
                ...state,
                activityListViewOpen: true,
                activityListViewFilter: {
                    ...actionCast.payload
                },
                activityListViewFilterMenu: actionCast.payload as IActivityFilterMenu
            }
        }
        case UI_SET_ACTIVITY_LIST_VIEW_FILTER: {
            const actionCast = action as ISetUIActivityListViewFilterAction;

            return {
                ...state,
                activityListViewFilter: actionCast.payload
            }
        }
        case UI_SET_ACTIVITY_LIST_VIEW_FILTER_MENU: {
            const actionCast = action as ISetUIActivityListViewFilterMenuAction;

            return {
                ...state,
                activityListViewFilterMenu: actionCast.payload
            }
        }
        case UI_SET_ACTIVITY_LIST_RESET_FILTER: {
            return {
                ...state,
                activityListViewFilter: {
                    vacancyId: state.activityListViewFilter.vacancyId
                },
                activityListViewFilterMenu: {}
            }
        }
        case UI_CLOSE_ACTIVITY_LIST_VIEW: {
            return {
                ...state,
                activityListViewOpen: false,
                activityListViewFilter: {
                    vacancyId: state.activityListViewFilter.vacancyId
                },
                activityListViewFilterMenu: {}
            }
        }
        case UI_SET_ACTIVITY_LIST_VIEW_SORTING_OPTION: {
            const actionCast = action as ISetUIActivityListViewSortingOptionAction;

            return {
                ...state,
                activityListViewSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            }
        }
        case UI_SET_EDIT_CELL: {
            const actionCast = action as ISetUiEditCellAction;

            return {
                ...state,
                editCellId: actionCast.payload
            }
        }
        case ENTITIES_SET_NEWS_READ_TIMESTAMP_FULFILLED: {
            return {
                ...state,
                countUnreadNews: 0
            }
        }
        case ENTITIES_SET_TODOS_READ_TIMESTAMP_FULFILLED: {
            return {
                ...state,
                countUnreadTodos: 0
            }
        }
        case ENTITIES_SET_MAIL_READ_FULFILLED: {
            return {
                ...state,
                countUnreadMails: state.countUnreadMails-1,
            }
        }
        case ENTITIES_GET_NOTIFY_UNREAD_COUNT_FULFILLED: {
            const actionCast = action as IGetNotifyUnreadCountAction;

            return {
                ...state,
                countUnreadNews: actionCast.payload.countNews,
                countUnreadTodos: actionCast.payload.countTodos,
                countUnreadMails: actionCast.payload.countUnreadMails,
            }
        }
        case UI_SET_ACTIVITY_TO_DELETE: {
            const actionCast = action as ISetActivityToDeleteAction;
            return {
                ...state,
                deleteActivityId: actionCast.payload
            };
        }
        case ENTITIES_DELETE_ACTIVITY_FULFILLED:
        case ENTITIES_POST_SUGGESTION_ACTIVITY_FULFILLED: {
            return {
                ...state,
                reloadActivityGrids: true
            }
        }
        case ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED:
        case ENTITIES_POST_ACTIVITY_FULFILLED:
        case ENTITIES_PUT_ACTIVITY_FULFILLED: {
            return {
                ...state,
                addActivityData: {
                    add: false
                },
                editActivityId: 0,
                reloadActivityGrids: true,
                saving: false
            }
        }
        case ENTITIES_GET_INVOICE_LIST_OF_COMPANY_PENDING: {
            return {
                ...state,
                invoicesOfCompanyLoading: true
            }
        }
        case ENTITIES_GET_INVOICE_LIST_OF_COMPANY_REJECTED:
        case ENTITIES_GET_INVOICE_LIST_OF_COMPANY_FULFILLED: {
            return {
                ...state,
                invoicesOfCompanyLoading: false
            }
        }

        case ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_PENDING: {
            return {
                ...state,
                invoicesOfRecruitingLoading: true
            }
        }
        case ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_REJECTED:
        case ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_FULFILLED: {
            return {
                ...state,
                invoicesOfRecruitingLoading: false
            }
        }

        case ENTITIES_GET_INVOICE_LIST_PENDING: {
            return {
                ...state,
                invoicesLoading: true
            }
        }
        case ENTITIES_GET_INVOICE_LIST_REJECTED:
        case ENTITIES_GET_INVOICE_LIST_FULFILLED: {
            return {
                ...state,
                invoicesLoading: false
            }
        }

        case ENTITIES_GET_MAILBOX_FOLDER_TREE_PENDING: {
            return {
                ...state,
                mailboxTreeLoading: true
            }
        }
        case ENTITIES_GET_MAILBOX_FOLDER_TREE_REJECTED:
        case ENTITIES_GET_MAILBOX_FOLDER_TREE_FULFILLED: {
            return {
                ...state,
                mailboxTreeLoading: false
            }
        }
        case ENTITIES_GET_MAIL_LIST_PENDING:
        case ENTITIES_GET_MAIL_LIST_BY_FOLDER_PENDING: {
            return {
                ...state,
                mailsLoading: true
            }
        }
        case ENTITIES_GET_MAIL_LIST_REJECTED:
        case ENTITIES_GET_MAIL_LIST_FULFILLED:
        case ENTITIES_GET_MAIL_LIST_BY_FOLDER_REJECTED:
        case ENTITIES_GET_MAIL_LIST_BY_FOLDER_FULFILLED: {
            return {
                ...state,
                mailsLoading: false
            }
        }

        case ENTITIES_GET_NEWS_LIST_PENDING: {
            return {
                ...state,
                newsLoading: true
            }
        }
        case ENTITIES_GET_NEWS_LIST_REJECTED:
        case ENTITIES_GET_NEWS_LIST_FULFILLED: {
            return {
                ...state,
                newsLoading: false
            }
        }
        case ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_PENDING: {
            return {
                ...state,
                todosOfEmployeeLoading: true
            }
        }
        case ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_REJECTED:
        case ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED: {
            return {
                ...state,
                todosOfEmployeeLoading: false
            }
        }
        case ENTITIES_GET_TODO_LIST_PENDING: {
            return {
                ...state,
                todosLoading: true
            }
        }
        case ENTITIES_GET_TODO_LIST_REJECTED:
        case ENTITIES_GET_TODO_LIST_FULFILLED: {
            return {
                ...state,
                todosLoading: false
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_PENDING: {
            return {
                ...state,
                chartSelectionRecruitingsLoading: true
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_REJECTED:
        case ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_FULFILLED: {
            return {
                ...state,
                chartSelectionRecruitingsLoading: false
            }
        }
        case ENTITIES_GET_PROCESS_AGGREGATION_LIST_PENDING: {
            return {
                ...state,
                candidateProcessesLoading: true
            }
        }
        case ENTITIES_GET_PROCESS_AGGREGATION_LIST_REJECTED:
        case ENTITIES_GET_PROCESS_AGGREGATION_LIST_FULFILLED: {
            return {
                ...state,
                candidateProcessesLoading: false
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_PENDING: {
            return {
                ...state,
                candidateRecruitingsLoading: true
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_REJECTED:
        case ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_FULFILLED: {
            return {
                ...state,
                candidateRecruitingsLoading: false
            }
        }
        case ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_PENDING: {
            return {
                ...state,
                candidateVacancySearchLoading: true
            }
        }
        case ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_REJECTED:
        case ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_FULFILLED: {
            return {
                ...state,
                candidateVacancySearchLoading: false
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_PENDING: {
            return {
                ...state,
                companyRecruitingsLoading: true
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_REJECTED:
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED: {
            return {
                ...state,
                companyRecruitingsLoading: false
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_PENDING: {
            return {
                ...state,
                companyContactRecruitingsLoading: true
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_REJECTED:
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_FULFILLED: {
            return {
                ...state,
                companyContactRecruitingsLoading: false
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_PENDING: {
            return {
                ...state,
                vacancyRecruitingsLoading: true
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_REJECTED:
        case ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED: {
            return {
                ...state,
                vacancyRecruitingsLoading: false
            }
        }
        case ENTITIES_GET_ACTIVITY_CHAT_LIST_FULFILLED:
        case ENTITIES_GET_SUCCESS_ACTIVITY_LIST_FULFILLED:
        case ENTITIES_GET_ACTIVITY_LIST_FULFILLED: {
            return {
                ...state,
                reloadActivityGrids: false,
                activitiesLoading: false
            }
        }
        case ENTITIES_GET_ACTIVITY_CHAT_LIST_PENDING:
        case ENTITIES_GET_SUCCESS_ACTIVITY_LIST_PENDING:
        case ENTITIES_GET_ACTIVITY_LIST_PENDING: {
            return {
                ...state,
                activitiesLoading: true,
            };
        }

        case UI_SET_FILTER_PANEL_OPEN: {
            const actionCast = action as ISetFilterPanelOpen;
            return {
                ...state,
                filterPanelsOpened: [
                    ...state.filterPanelsOpened,
                    actionCast.payload
                ]
            };
        }
        case UI_SET_SPLIT_CANDIDATE_VIEW: {
            return {
                ...state,
                splitCandidateEditView: action.payload as boolean
            }
        }
        case UI_SET_SHOW_ONLY_MINE: {
            return {
                ...state,
                showOnlyMyDataActive: action.payload as boolean
            }
        }
        case ACTOR_LOGIN_FULFILLED: {
            const actionCast = action as IActorLoginAction;
            const token = actionCast.payload.token.replace("Bearer ", "");

            const data = jwt_decode(token) as IJwtData;

            return {
                ...state,
                loginLoading: false,
                showOnlyMyDataActive: data.onlyShowMine
            };
        }
        case ACTOR_REFRESH_TOKEN_FULFILLED: {
            const actionCast = action as IActorRefreshTokenAction;
            const token = actionCast.payload.token.replace("Bearer ", "");

            const data = jwt_decode(token) as IJwtData;

            return {
                ...state,
                showOnlyMyDataActive: data.onlyShowMine
            };
        }
        case UI_SET_FILTER_PANEL_CLOSED: {
            const actionCast = action as ISetFilterPanelClosed;
            return {
                ...state,
                filterPanelsOpened: state.filterPanelsOpened.filter((entity) => entity !== actionCast.payload)
            }
        }
        case UI_SET_ACTIVITY_TO_EDIT: {
            const actionCast = action as ISetActivityToEditAction;
            return {
                ...state,
                editActivityId: actionCast.payload,
            }
        }
        case UI_SET_ACTIVITY_ADD_DATA: {
            const actionCast = action as ISetActivityAddDataAction;
            return {
                ...state,
                addActivityData: actionCast.payload,
            }
        }
        case ENTITIES_POST_COMPANY_CONTACT_FULFILLED:
        case ENTITIES_PUT_COMPANY_CONTACT_FULFILLED: {
            return {
                ...state,
                addCompanyContactData: {
                    companyId: 0
                },
                saving: false
            }
        }
        case ENTITIES_POST_COMPANY_LOCATION_FULFILLED:
        case ENTITIES_PUT_COMPANY_LOCATION_FULFILLED: {
            return {
                ...state,
                addCompanyLocationData: {
                    companyId: 0
                },
                editCompanyLocationId: 0,
                saving: false
            }
        }
        case UI_SET_COMPANY_CONTACT_ADD_DATA: {
            const actionCast = action as ISetCompanyContactAddDataAction;
            return {
                ...state,
                addCompanyContactData: actionCast.payload,
            }
        }
        case UI_SET_COMPANY_LOCATION_TO_EDIT: {
            const actionCast = action as ISetCompanyLocationToEditAction;
            return {
                ...state,
                editCompanyLocationId: actionCast.payload,
            }
        }
        case UI_SET_COMPANY_LOCATION_ADD_DATA: {
            const actionCast = action as ISetCompanyLocationAddDataAction;
            return {
                ...state,
                addCompanyLocationData: actionCast.payload,
            }
        }
        case ACTOR_CHANGE_PASSWORD_FULFILLED: {
            return {
                ...state,
                renewPassword: false
            };
        }
        case UI_SET_RENEW_PASSWORD: {
            return {
                ...state,
                renewPassword: true
            };
        }
        case UI_SET_LOGOUT_EXPIRE_DIALOG: {
            return {
                ...state,
                expireModal: false,
            };
        }
        case ENTITIES_POST_CANDIDATE_FULFILLED: {
            const actionCast = action as IPostCandidateAction;
            return {
                ...state,
                ...setEditView({
                    view: EEntityView.candidate,
                    entityId: actionCast.payload.id
                }),
                saving: false
            };
        }
        case ENTITIES_POST_COMPANY_FULFILLED: {
            const actionCast = action as IPostCompanyAction;
            return {
                ...state,
                ...setEditView({
                    view: EEntityView.company,
                    entityId: actionCast.payload.id
                }),
                saving: false
            };
        }
        case ENTITIES_POST_VACANCY_FULFILLED: {
            return {
                ...state,
                saving: false
            };
        }
        case ENTITIES_GET_BRANCH_LIST_FULFILLED: {
            return {
                ...state,
                initialLoaded: {
                    ...state.initialLoaded,
                    branches: true
                },
            };
        }
        case ENTITIES_GET_COMPANY_LOCATION_LIST_FULFILLED: {
            return {
                ...state,
                initialLoaded: {
                    ...state.initialLoaded,
                    companyLocations: true
                },
            };
        }
        case ENTITIES_GET_SKILL_LIST_FULFILLED: {
            return {
                ...state,
                initialLoaded: {
                    ...state.initialLoaded,
                    skills: true
                },
            };
        }
        case ENTITIES_GET_SKILL_TREE_PENDING: {
            return {
                ...state,
                skillTreeLoading: true,
            };
        }
        case ENTITIES_GET_SKILL_TREE_REJECTED:
        case ENTITIES_GET_SKILL_TREE_FULFILLED: {
            return {
                ...state,
                skillTreeLoading: false,
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_PENDING: {
            return {
                ...state,
                employeeRecruitingsLoading: true,
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_REJECTED:
        case ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_FULFILLED: {
            return {
                ...state,
                employeeRecruitingsLoading: false,
            };
        }
        case ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_PENDING: {
            return {
                ...state,
                candidatesBirthdayLoading: true,
            };
        }
        case ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_FULFILLED: {
            return {
                ...state,
                candidatesBirthdayLoading: false,
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_PENDING: {
            return {
                ...state,
                companyContactsBirthdayLoading: true,
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_FULFILLED: {
            return {
                ...state,
                companyContactsBirthdayLoading: false,
            };
        }
        case ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_PENDING: {
            return {
                ...state,
                documentsOfCandidateLoading: true,
            };
        }
        case ENTITIES_GET_DOCUMENTS_FOR_COMPANY_PENDING: {
            return {
                ...state,
                documentsOfCompanyLoading: true,
            };
        }
        case ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_FULFILLED:
        case ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_REJECTED: {
            return {
                ...state,
                documentsOfCandidateLoading: false,
            };
        }
        case ENTITIES_GET_DOCUMENTS_FOR_COMPANY_FULFILLED:
        case ENTITIES_GET_DOCUMENTS_FOR_COMPANY_REJECTED: {
            return {
                ...state,
                documentsOfCompanyLoading: false,
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_PENDING: {
            return {
                ...state,
                companyContactsWithoutBirthDateLoading: true,
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_FULFILLED: {
            return {
                ...state,
                companyContactsWithoutBirthDateLoading: false,
            };
        }
        case ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_PENDING: {
            return {
                ...state,
                candidatesOpenApplicationsLoading: true,
            };
        }
        case ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_FULFILLED: {
            return {
                ...state,
                candidatesOpenApplicationsLoading: false,
            };
        }
        case ENTITIES_GET_CANDIDATES_CREATED_NEW_PENDING: {
            return {
                ...state,
                candidatesNewLoading: true,
            };
        }
        case ENTITIES_GET_CANDIDATES_CREATED_NEW_FULFILLED: {
            return {
                ...state,
                candidatesNewLoading: false,
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_PENDING: {
            return {
                ...state,
                recruitingsOfLast7DaysLoading: true,
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_FULFILLED: {
            return {
                ...state,
                recruitingsOfLast7DaysLoading: false,
            };
        }
        case UI_SET_FORCE_EXIT_FAST_EDIT: {
            const actionCast = action as ISetForceExitFastEditAction;
            return {
                ...state,
                forceExitFastEdit: actionCast.payload,
            };
        }
        case UI_CLOSE_TOP_DRAWER: {
            return {
                ...state,
                topDrawerOpen: false
            }
        }
        case UI_TOGGLE_TOP_DRAWER: {
            return {
                ...state,
                topDrawerOpen: !state.topDrawerOpen
            }
        }
        case UI_CLOSE_RIGHT_DRAWER_VIEW: {
            const actionCast = action as ISetRightDrawerCloseAction;

            switch (actionCast.payload) {
                case ERightDrawerViews.viewTodos:
                    return {
                        ...state,
                        rightDrawerTodoOpen: false
                    };
                case ERightDrawerViews.viewMails:
                    return {
                        ...state,
                        rightDrawerMailOpen: false
                    };
                case ERightDrawerViews.viewNews:
                    return {
                        ...state,
                        rightDrawerNewsOpen: false
                    };
            }

            return {
                ...state
            };
        }
        case UI_SET_RIGHT_DRAWER_VIEW_OPEN: {
            const actionCast = action as ISetRightDrawerOpenAction;

            switch (actionCast.payload) {
                case ERightDrawerViews.viewTodos:
                    return {
                        ...state,
                        rightDrawerTodoOpen: true
                    };
                case ERightDrawerViews.viewMails:
                    return {
                        ...state,
                        rightDrawerMailOpen: true
                    };
                case ERightDrawerViews.viewNews:
                    return {
                        ...state,
                        rightDrawerNewsOpen: true
                    };
            }

            return {
                ...state
            };
        }
        case UI_SET_CONGRATULATION_VIEW_OPEN:
            return {
                ...state,
                openCongratulation: action.payload
            }
        case UI_SET_ENCOURAGEMENT_VIEW_OPEN:
            return {
                ...state,
                openEncouragement: action.payload
            }
        case UI_SET_CURRENT_ROUTE:
            return {
                ...state,
                currentRoute: action.payload,
            };
        case UI_SET_LOGOUT_DIALOG_OPEN:
            return {
                ...state,
                logOutDialogOpen: action.payload,
            };
        case UI_SET_ENTITY_TO_CREATE_DIALOG:
            return {
                ...state,
                entityToCreate: action.payload,
            };
        case ACTOR_LOGIN_PENDING:
            return {
                ...state,
                loginLoading: true,
            };
        case ACTOR_LOGIN_REJECTED:
            return {
                ...state,
                loginLoading: false,
            };
        case ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_PENDING:
        case ENTITIES_GET_WEB_VACANCY_LIST_PENDING: {
            return {
                ...state,
                webVacanciesLoading: true,
            };
        }
        case ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_REJECTED:
        case ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_FULFILLED:
        case ENTITIES_GET_WEB_VACANCY_LIST_REJECTED:
        case ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED: {
            return {
                ...state,
                webVacanciesLoading: false
            }
        }
        case ENTITIES_GET_EMPLOYEE_LIST_PENDING: {
            return {
                ...state,
                employeesLoading: true,
            };
        }
        case ENTITIES_GET_EMPLOYEE_LIST_REJECTED:
        case ENTITIES_GET_EMPLOYEE_LIST_FULFILLED: {
            return {
                ...state,
                employeesLoading: false
            }
        }
        case ENTITIES_GET_JOB_TITLE_LIST_PENDING: {
            return {
                ...state,
                jobTitlesLoading: true,
            };
        }
        case ENTITIES_GET_JOB_TITLE_LIST_REJECTED:
        case ENTITIES_GET_JOB_TITLE_LIST_FULFILLED: {
            return {
                ...state,
                jobTitlesLoading: false
            }
        }
        case ENTITIES_GET_JOB_TEMPLATE_LIST_PENDING: {
            return {
                ...state,
                jobTemplatesLoading: true,
            };
        }
        case ENTITIES_GET_JOB_TEMPLATE_LIST_REJECTED:
        case ENTITIES_GET_JOB_TEMPLATE_LIST_FULFILLED: {
            return {
                ...state,
                jobTemplatesLoading: false
            }
        }
        case ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_FULFILLED: {
            return {
                ...state,
                initialLoaded: {
                    ...state.initialLoaded,
                    jobTemplates: true
                }
            }
        }
        case ENTITIES_GET_COMPANY_LIST_PENDING: {
            return {
                ...state,
                companiesLoading: true,
            };
        }
        case ENTITIES_GET_COMPANY_LIST_REJECTED:
        case ENTITIES_GET_COMPANY_LIST_FULFILLED: {
            const actionCast = action as IGetCompanyListAction;

            if (!actionCast.meta) {
                return {
                    ...state,
                    companiesLoading: false
                };
            }

            return {
                ...state,
                companiesLoading: false,
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_PENDING: {
            return {
                ...state,
                suggestionListViewLoading: true,
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_FULFILLED:
        case ENTITIES_GET_SUGGESTION_LIST_REJECTED: {
            return {
                ...state,
                suggestionListViewLoading: false,
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_PENDING: {
            return {
                ...state,
                topSuggestionsLoading: true,
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_FULFILLED:
        case ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_REJECTED: {
            return {
                ...state,
                topSuggestionsLoading: false,
            };
        }

        case ENTITIES_GET_APPLICATION_LIST_PENDING:
        case ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_PENDING:
        case ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_PENDING:
        case ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_PENDING: {
            return {
                ...state,
                applicationsLoading: true,
                reloadApplications: false
            };
        }
        case ENTITIES_GET_APPLICATION_LIST_REJECTED:
        case ENTITIES_GET_APPLICATION_LIST_FULFILLED:
        case ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_FULFILLED:
        case ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_REJECTED:
        case ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED:
        case ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_REJECTED:
        case ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_FULFILLED:
        case ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_REJECTED: {
            return {
                ...state,
                applicationsLoading: false,
            };
        }

        case ENTITIES_POST_APPLICATION_FULFILLED: {
            return {
                ...state,
                reloadApplications: true,
            };
        }

        case ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_PENDING: {
            return {
                ...state,
                candidateSuggestionsLoading: true,
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_FULFILLED:
        case ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_REJECTED: {
            return {
                ...state,
                candidateSuggestionsLoading: false,
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_PENDING: {
            return {
                ...state,
                vacancySuggestionsLoading: true,
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED:
        case ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_REJECTED: {
            return {
                ...state,
                vacancySuggestionsLoading: false,
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_PENDING: {
            return {
                ...state,
                companyContactsLoading: true,
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_FULFILLED:
        case ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_REJECTED: {
            return {
                ...state,
                companyContactsLoading: false,
            };
        }
        case ENTITIES_GET_LOCATIONS_FOR_COMPANY_PENDING: {
            return {
                ...state,
                companyLocationsLoading: true,
            };
        }
        case ENTITIES_GET_LOCATIONS_FOR_COMPANY_FULFILLED:
        case ENTITIES_GET_LOCATIONS_FOR_COMPANY_REJECTED: {
            return {
                ...state,
                companyLocationsLoading: false,
            };
        }
        case ENTITIES_GET_VACANCY_LIST_OF_COMPANY_PENDING: {
            return {
                ...state,
                companyVacanciesLoading: true,
            };
        }
        case ENTITIES_GET_VACANCY_LIST_OF_COMPANY_FULFILLED:
        case ENTITIES_GET_VACANCY_LIST_OF_COMPANY_REJECTED: {
            return {
                ...state,
                companyVacanciesLoading: false,
            };
        }
        case ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_PENDING: {
            return {
                ...state,
                companyContactVacanciesLoading: true,
            };
        }
        case ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_FULFILLED:
        case ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_REJECTED: {
            return {
                ...state,
                companyContactVacanciesLoading: false,
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_PENDING: {
            return {
                ...state,
                recruitingsLoading: true,
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_REJECTED:
        case ENTITIES_GET_RECRUITING_LIST_FULFILLED: {
            return {
                ...state,
                recruitingsLoading: false,
            };
        }
        case ENTITIES_GET_OPEN_VACANCY_LIST_PENDING: {
            return {
                ...state,
                openVacanciesLoading: true,
            };
        }
        case ENTITIES_GET_ARCHIVED_VACANCY_LIST_PENDING: {
            return {
                ...state,
                archivedVacanciesLoading: true,
            };
        }

        case ENTITIES_GET_ARCHIVED_VACANCY_LIST_REJECTED:
        case ENTITIES_GET_ARCHIVED_VACANCY_LIST_FULFILLED:
        case ENTITIES_GET_OPEN_VACANCY_LIST_REJECTED:
        case ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED: {
            return {
                ...state,
                openVacanciesLoading: false,
                archivedVacanciesLoading: false,
            };
        }
        case ENTITIES_GET_CANDIDATE_LIST_PENDING:
        case ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_PENDING:
        case ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_PENDING:
        case ENTITIES_GET_CANDIDATE_LIST_APPEND_PENDING: {
            return {
                ...state,
                candidatesLoading: true,
            };
        }
        case ENTITIES_GET_CANDIDATE_LIST_REJECTED:
        case ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_REJECTED:
        case ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_FULFILLED:
        case ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_REJECTED:
        case ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_FULFILLED:
        case ENTITIES_GET_CANDIDATE_LIST_FULFILLED: {
            return {
                ...state,
                candidatesLoading: false,
            };
        }
        case ENTITIES_GET_CANDIDATE_LIST_APPEND_FULFILLED: {
            return {
                ...state,
                candidatesLoading: false,
            };
        }
        case UI_SET_CLICKED_ROW: {
            const actionCast = action as ISetClickedRowAction;

            return {
                ...state,
                clickedRow: {
                    ...state.clickedRow,
                    [actionCast.payload.entity]: actionCast.payload.index
                },
            };
        }
        case DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_FULFILLED: {
            return {
                ...state,
                initialLoaded: {
                    ...state.initialLoaded,
                    dashboardData: true
                }
            };
        }


        case ENTITIES_POST_CANDIDATE_PENDING:
        case ENTITIES_PUT_CANDIDATE_PENDING:
        case ENTITIES_POST_CANDIDATE_DOCUMENT_PENDING:
        case ENTITIES_PUT_CANDIDATE_DOCUMENT_PENDING:
        case ENTITIES_POST_RECRUITING_DOCUMENT_PENDING:
        case ENTITIES_PUT_RECRUITING_DOCUMENT_PENDING:
        case ENTITIES_POST_COMPANY_PENDING:
        case ENTITIES_PUT_COMPANY_PENDING:
        case ENTITIES_POST_COMPANY_CONTACT_PENDING:
        case ENTITIES_PUT_COMPANY_CONTACT_PENDING:
        case ENTITIES_POST_COMPANY_LOCATION_PENDING:
        case ENTITIES_PUT_COMPANY_LOCATION_PENDING:
        case ENTITIES_POST_EMPLOYEE_PENDING:
        case ENTITIES_PUT_EMPLOYEE_PENDING:
        case ENTITIES_POST_JOB_TEMPLATE_PENDING:
        case ENTITIES_PUT_JOB_TEMPLATE_PENDING:
        case ENTITIES_POST_SKILL_PENDING:
        case ENTITIES_POST_VACANCY_PENDING:
        case ENTITIES_PUT_VACANCY_PENDING:
        case ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_PENDING:
        case ENTITIES_POST_RECRUITING_PENDING:
        case ENTITIES_PUT_RECRUITING_PENDING:
        case ENTITIES_POST_ACTIVITY_PENDING:
        case ENTITIES_PUT_ACTIVITY_PENDING:
        case ENTITIES_POST_SUGGESTION_PENDING:
        case ENTITIES_PUT_SUGGESTION_PENDING:
        case ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_PENDING:
        case ENTITIES_PUT_ACCEPT_SUGGESTION_PENDING:
        case ENTITIES_PUT_DECLINE_SUGGESTION_PENDING:
        case ENTITIES_PUT_WEB_VACANCY_PENDING:
        case ENTITIES_POST_TODO_PENDING:
        case ENTITIES_PUT_TODO_PENDING:
        case ENTITIES_POST_INVOICE_PENDING:
        case ENTITIES_PUT_REOPEN_SUGGESTION_PENDING:
        case ENTITIES_PUT_SKILL_PENDING:
        case ENTITIES_PUT_INVOICE_PENDING:{
            return {
                ...state,
                saving: true
            }
        }

        case ENTITIES_PUT_CANDIDATE_FULFILLED:
        case ENTITIES_POST_CANDIDATE_DOCUMENT_FULFILLED:
        case ENTITIES_PUT_CANDIDATE_DOCUMENT_FULFILLED:
        case ENTITIES_POST_RECRUITING_DOCUMENT_FULFILLED:
        case ENTITIES_PUT_RECRUITING_DOCUMENT_FULFILLED:
        case ENTITIES_PUT_COMPANY_FULFILLED:
        case ENTITIES_POST_EMPLOYEE_FULFILLED:
        case ENTITIES_PUT_EMPLOYEE_FULFILLED:
        case ENTITIES_POST_JOB_TEMPLATE_FULFILLED:
        case ENTITIES_PUT_JOB_TEMPLATE_FULFILLED:
        case ENTITIES_PUT_VACANCY_FULFILLED:
        case ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_FULFILLED:
        case ENTITIES_POST_RECRUITING_FULFILLED:
        case ENTITIES_PUT_RECRUITING_FULFILLED:
        case ENTITIES_POST_SUGGESTION_FULFILLED:
        case ENTITIES_PUT_SUGGESTION_FULFILLED:
        case ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED:
        case ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED:
        case ENTITIES_PUT_WEB_VACANCY_FULFILLED:
        case ENTITIES_POST_TODO_FULFILLED:
        case ENTITIES_PUT_TODO_FULFILLED:
        case ENTITIES_POST_INVOICE_FULFILLED:
        case ENTITIES_PUT_INVOICE_FULFILLED:
        case ENTITIES_POST_CANDIDATE_REJECTED:
        case ENTITIES_PUT_CANDIDATE_REJECTED:
        case ENTITIES_POST_CANDIDATE_DOCUMENT_REJECTED:
        case ENTITIES_PUT_CANDIDATE_DOCUMENT_REJECTED:
        case ENTITIES_POST_RECRUITING_DOCUMENT_REJECTED:
        case ENTITIES_PUT_RECRUITING_DOCUMENT_REJECTED:
        case ENTITIES_POST_COMPANY_REJECTED:
        case ENTITIES_PUT_COMPANY_REJECTED:
        case ENTITIES_POST_COMPANY_CONTACT_REJECTED:
        case ENTITIES_PUT_COMPANY_CONTACT_REJECTED:
        case ENTITIES_POST_COMPANY_LOCATION_REJECTED:
        case ENTITIES_PUT_COMPANY_LOCATION_REJECTED:
        case ENTITIES_POST_EMPLOYEE_REJECTED:
        case ENTITIES_PUT_EMPLOYEE_REJECTED:
        case ENTITIES_POST_JOB_TEMPLATE_REJECTED:
        case ENTITIES_PUT_JOB_TEMPLATE_REJECTED:
        case ENTITIES_POST_SKILL_REJECTED:
        case ENTITIES_POST_VACANCY_REJECTED:
        case ENTITIES_PUT_VACANCY_REJECTED:
        case ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_REJECTED:
        case ENTITIES_POST_RECRUITING_REJECTED:
        case ENTITIES_PUT_RECRUITING_REJECTED:
        case ENTITIES_POST_ACTIVITY_REJECTED:
        case ENTITIES_PUT_ACTIVITY_REJECTED:
        case ENTITIES_POST_SUGGESTION_REJECTED:
        case ENTITIES_PUT_SUGGESTION_REJECTED:
        case ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_REJECTED:
        case ENTITIES_PUT_ACCEPT_SUGGESTION_REJECTED:
        case ENTITIES_PUT_DECLINE_SUGGESTION_REJECTED:
        case ENTITIES_PUT_WEB_VACANCY_REJECTED:
        case ENTITIES_POST_TODO_REJECTED:
        case ENTITIES_PUT_TODO_REJECTED:
        case ENTITIES_POST_INVOICE_REJECTED:
        case ENTITIES_PUT_REOPEN_SUGGESTION_REJECTED:
        case ENTITIES_PUT_REOPEN_SUGGESTION_FULFILLED:
        case ENTITIES_PUT_SKILL_FULFILLED:
        case ENTITIES_PUT_SKILL_REJECTED:
        case ENTITIES_PUT_INVOICE_REJECTED: {
            return {
                ...state,
                saving: false
            }
        }

        case ENTITIES_POST_SKILL_FULFILLED: {
            return {
                ...state,
                entityToCreate: undefined,
                saving: false
            }
        }
        default:
            return state;
    }
};

export default uiReducer;
