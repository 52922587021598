import React, { useEffect, useState } from 'react';
import {
    ECandidateSource,
    ESuggestionAction,
    IEmployee,
    IEmployeePartial,
    IPluginCandidate,
    IPluginLanguage,
    IPluginSkill,
    IVacancyPartial,
    TSalutation,
} from '../../../../definitions/entities.definition';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { translate } from '../../../../translation/translate.utils';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { addSuggestionAction } from '../../../../redux/entities/entities.actions';
import CandidateSourceDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { getSuggestionExternalUrl } from '../../../../utils/suggestion.utils';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import CandidateAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import CustomRadioGroupInput from '../../../../components/CustomInput/CustomRadioGroupInput';
import { getDayDifference } from '../../../../utils/date.utils';
import { useCreateSuggestionForVacancyHook } from './AddSuggestionForVacancyForm.hooks';
import CandidateInformationColumnCellRenderer
    from '../../../../components/CustomCellRenderer/CandidateInformationColumnCellRenderer';
import { Card, CardHeader, Chip } from '@material-ui/core';
import styles from '../../../Shared/Todo/TodoCard.module.css';
import CandidateSourceRenderer from '../../../../components/CustomCellRenderer/CandidateSourceRenderer';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { registerError } from '../../../../redux/error/error.actions';
import { addOnBrainzPluginHandler, sendUrlToBrainzPlugin } from '../../../../utils/components.utils';

interface IProps {
    onClose: () => void;
    vacancy: IVacancyPartial
}

const AddSuggestionForVacancyForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancy = props.vacancy;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const errors = useSelector(getValidationErrorsFromStore);
    const [addType, setAddType] = useState<'candidate'|'newCandidate'>('newCandidate');
    const [salutation, setSalutation] = useState<TSalutation>('mr');
    const [candidateId, setCandidateId] = useState(0);
    const [name, setName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [currentJobDescription, setCurrentJobDescription] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [externalCandidateReference, setExternalCandidateReference] = useState<string>("");
    const [externalCandidateUrl, setExternalCandidateUrl] = useState<string>("");
    const [source, setSource] = useState(ECandidateSource.jobcenter);
    const [suggestedBy, setSuggestedBy] = useState<IEmployeePartial|undefined>();
    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial|undefined>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [mailAddress, setMailAddress] = useState<string>("");
    const [addAction, setAddAction] = useState(false);
    const addActions = [ESuggestionAction.ACTION_MESSAGE_MAIL, ESuggestionAction.ACTION_MESSAGE_PORTAL, ESuggestionAction.ACTION_MESSAGE_MOBILE, ESuggestionAction.ACTION_LETTER_SEND, ESuggestionAction.ACTION_CALL, ESuggestionAction.ACTION_CALL_NOT_REACHED];
    const [userInput, setUserInput] = useState("");
    const [action, setAction] = useState<ESuggestionAction>();
    const [importInProgress, setImportInProgress] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [externalSkills, setExternalSkills] = useState<IPluginSkill[]>([]);
    const [externalLanguages, setExternalLanguages] = useState<IPluginLanguage[]>([]);

    addOnBrainzPluginHandler((event: MessageEvent) => {
        setImportInProgress(false);

        if (!event.data.success) {
            dispatch(registerError('URL konnte nicht ausgelesen werden  '));
            return;
        }

        const importData: IPluginCandidate = event.data.data;
        setFirstName(importData.firstName);
        setName(importData.lastName);
        setCurrentJobDescription(importData.currentJobDescription);
        setMailAddress(importData.mailAddress);
        setCity(importData.city.replace(', Deutschland', '').replace('Metropolregion ', ''));
        setImageUrl(importData.imageUrl);
        setExternalSkills(importData.skills);
        setExternalLanguages(importData.languages);
    });

    useEffect(() => {
        setResponsibleEmployee(vacancy?.responsibleEmployee);
        setSuggestedBy(loggedInEmployee);
    }, [dispatch]);

    const onSave = () => {
        dispatch(addSuggestionAction(token, {
            vacancyId: vacancy.id,
            candidateId,
            salutation,
            name,
            firstName,
            mailAddress,
            externalCandidateUrl,
            externalCandidateReference,
            source: source,
            phoneNumber,
            suggestedByEmployeeId: suggestedBy ? suggestedBy.id : undefined,
            responsibleEmployeeId: responsibleEmployee ? responsibleEmployee.id : undefined,
            contactAction: addAction ? action : undefined,
            contactUserInput: addAction ? userInput : undefined,
            currentJobDescription,
            city,
            address,
            zipCode,
            imageUrl,
            externalSkills,
            externalLanguages
        }));
        return;
    }

    const externalUrl = getSuggestionExternalUrl(source, externalCandidateUrl, externalCandidateReference);
    const {matchingSuggestions, duplicateCandidates} = useCreateSuggestionForVacancyHook(name, firstName, externalCandidateReference);

    return <>
        <div style={{ marginRight:10, marginLeft: 10, overflow: "auto", backgroundColor: 'var(--brainz-darker)' }}>
            <CustomBackdropLoadingInsideDiv open={importInProgress} />
                <PaperRow>
                    <CustomRadioGroupInput
                        value={addType}
                        //@ts-ignore
                        onChange={(type) => {setAddType(type)}}
                        states={[{
                            value: 'candidate',
                            label: 'Kandidat aus Datenbank auswählen'
                        }, {
                            value: 'newCandidate',
                            label: 'Favorit händisch anlegen'
                        }]}
                    />
                </PaperRow>
            { addType === 'candidate' &&
                <PaperRow>
                    <CandidateAutocomplete
                        value={candidateId}
                        onChange={(value) => {
                            if (value) {
                                setCandidateId(value.id)
                                if (getDayDifference(value.created) <= 30) {
                                    setSource(value.source);
                                    return;
                                }
                                setSource(ECandidateSource.brainz);
                            } else {
                                setCandidateId(0);
                            }
                        }}
                    />
                    <CandidateSourceDropDown
                        value={source}
                        onChange={(event) => setSource(event.target.value)}
                    />
                </PaperRow>
            }
            {addType === 'newCandidate' &&
                <>
                    <PaperRow>
                        <CandidateSourceDropDown
                            value={source}
                            onChange={(event) => setSource(event.target.value)}
                        />
                        {source === ECandidateSource.jobcenter &&
                            <CustomUrlField
                                flex={2}
                                required
                                url={externalUrl}
                                label={translate('pages.vacancy.externalCandidateReference')}
                                value={externalCandidateReference}
                                onChange={(value) => setExternalCandidateReference(value)}
                                error={Boolean(errors.externalCandidateReference)}
                                helperText={errors.externalCandidateReference}
                            />
                        }
                        {(source !== ECandidateSource.recommendation && source !== ECandidateSource.jobcenter && source !== ECandidateSource.brainz && source !== ECandidateSource.further) &&
                            <>
                                <CustomUrlField
                                    flex={2}
                                    required
                                    label={translate('misc.url')}
                                    value={externalCandidateUrl}
                                    onChange={(value) => {
                                        if (source === ECandidateSource.linkedIn && sendUrlToBrainzPlugin(value)) {
                                            setImportInProgress(true);
                                        }
                                        setExternalCandidateUrl(value)
                                    }}
                                    error={Boolean(errors.externalCandidateUrl)}
                                    helperText={errors.externalCandidateUrl}
                                />
                            </>
                        }
                    </PaperRow>

                    <PaperRow>
                        {imageUrl &&
                            <div className={"flexContainerColumn flexAutoGrow"}>
                                <h4 style={{margin: 5}}>Bild</h4>
                                <img src={imageUrl} height={200}/>
                            </div>
                        }
                        {(externalSkills.length > 0) &&
                            <div className={"flexContainerColumn flex1"}>
                                <h4 style={{margin: 5}}>Skills ({externalSkills.length})</h4>
                                <div className={"flexContainerRow gap5 flexWrap"}>
                                    {externalSkills.map((externalSkill) => <Chip
                                        key={externalSkill.skill}
                                        color={'primary'}
                                        //icon={onHover || props.skill.importantSkill ? starIcon : starBorderIcon }
                                        label={<div className={"flex flexCentered"}>
                                            <img width={22} src={"/linkedIn.png"} title={"Aus LinkedIn"}/>&nbsp;
                                            {externalSkill.skill}
                                        </div>}
                                        /*onClick={(event) => {
                                            //@ts-ignore
                                            if (event.nativeEvent.layerX <= 30) {
                                                props.onSetTop(props.skill.skill);
                                                return;
                                            }

                                            dispatch(setSkillPageExternalSkillToEditAction(props.skill.externalSkill.id));
                                            return;
                                        }}*/
                                    />)}
                                </div>
                            </div>
                        }
                        {(externalLanguages.length > 0) &&
                        <div className={"flexContainerColumn flex1"}>
                            <h4 style={{margin: 5}}>Sprachen ({externalLanguages.length})</h4>
                            <div className={"flexContainerRow gap5 flexWrap"}>
                                {externalLanguages.map((externalLanguage) => <Chip
                                    key={externalLanguage.language}
                                    color={'primary'}
                                    label={<div className={"flexContainerColumn"}>
                                        <div className={"flex1 ellipseText"}>{externalLanguage.language}</div>
                                        <div className={"formContent"} style={{fontSize: 10}}>
                                            {externalLanguage.level}
                                        </div>
                                    </div>}
                                />)}
                            </div>
                        </div>
                        }
                    </PaperRow>


                    <PaperRow>
                        <div className={"flexContainerRow gap10 flex1"}>
                            <SalutationDropDown
                                // @ts-ignore-next-line
                                onChange={(event: any) => setSalutation(event.target.value)}
                                value={salutation}
                                required={true}
                            />
                            <CustomTextField
                                value={firstName}
                                onChange={(value) => setFirstName(value)}
                                label={translate('pages.candidate.properties.firstName')}
                            />
                        </div>
                        <CustomTextField
                            value={name}
                            onChange={(value) => setName(value)}
                            label={translate('pages.candidate.properties.name')}
                        />
                    </PaperRow>
                    {duplicateCandidates.length > 0 &&
                    <>
                        <h4 style={{margin: 5}}>Kandidat/en mit gleichen Namen/gleicher Referenz</h4>
                        <div className={'formContent'} style={{marginLeft: 5}}>Klicken sie auf den Kandidaten um diesen auszuwählen.</div>
                        <div className={"flexContainerRow flexWrap flex1 gap10"}>
                            {duplicateCandidates.map((candidate) => <>
                                <div
                                    className={"flexContainerRow CandidateInformationPanel"}
                                    onClick={() => {
                                        setAddType('candidate');
                                        setCandidateId(candidate.id);
                                        setName('');
                                        setFirstName('');
                                        setPhoneNumber('');
                                        setMailAddress('');
                                        setExternalCandidateReference('');
                                    }}
                                >
                                    <CandidateInformationColumnCellRenderer
                                        key={candidate.id}
                                        showDetails
                                        token={token}
                                        candidate={candidate}
                                    />
                                </div>
                            </>
                            )}
                        </div>
                    </>
                    }
                    {matchingSuggestions.length > 0 &&
                    <>
                        <h4 style={{margin: 5}}>Vorschläge mit gleichen Namen/gleicher Referenz</h4>
                        <div className={'formContent'} style={{marginLeft: 5}}>Klicken sie auf den Favoriten um seine Daten zu übernehmen.</div>
                        {matchingSuggestions.map((suggestion) => <>
                                <div className={"flexContainerRow flexAutoGrow"}>
                                    <Card className={styles.root + ' hoverContainer'} style={{cursor: 'default'}}>
                                        <CardHeader
                                            title={
                                                <>
                                                    <div className={"flexColumn gap5"}>
                                                        <div className={"flex"}>
                                                            <div
                                                                className={"flex1 flexContainerRow alignVerticalCenter"}
                                                                style={{border: '0px solid red', cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    setAddType('newCandidate');
                                                                    setCandidateId(0);
                                                                    setName(suggestion.name || '');
                                                                    setFirstName(suggestion.firstName|| '');
                                                                    setSource(suggestion.source);
                                                                    setExternalCandidateUrl(suggestion.externalCandidateUrl|| '');
                                                                    setPhoneNumber(suggestion.phoneNumber);
                                                                    setMailAddress(suggestion.phoneNumber);
                                                                }}
                                                            >
                                                                <CandidateSourceRenderer source={suggestion.source}/>&nbsp;
                                                                {suggestion.fullName || suggestion.externalCandidateReference}<br/>
                                                                {suggestion.vacancy.title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            titleTypographyProps={{
                                                variant: "body2"
                                            }}
                                        />
                                    </Card>
                                </div>
                            </>
                        )}
                    </>
                    }
                    <PaperRow>
                        <CustomTextField
                            value={phoneNumber}
                            onChange={(value) => setPhoneNumber(value)}
                            label={translate('misc.mobileNumber')}
                        />
                        <CustomTextField
                            value={mailAddress}
                            onChange={(value) => setMailAddress(value)}
                            label={translate('misc.email')}
                        />
                    </PaperRow>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.candidate.properties.currentJobDescription')}
                            error={Boolean(errors.currentJobDescription)}
                            value={currentJobDescription}
                            onChange={(value) => setCurrentJobDescription(value)}
                        />
                    </PaperRow>
                    <PaperRow>
                        <div className={"flexContainerRow flex1 gap10"}>
                            <CustomTextField
                                value={zipCode}
                                onChange={(value) => setZipCode(value)}
                                label={translate('misc.zipCode')}
                                width={100}
                            />
                            <CustomTextField
                                label={translate('misc.city')}
                                value={city}
                                onChange={(value) => setCity(value)}
                            />
                        </div>
                        <CustomTextField
                            value={address}
                            onChange={(value) => setAddress(value)}
                            label={translate('misc.address')}
                        />
                    </PaperRow>
                </>
            }
            <PaperRow>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.suggestedByEmployee')}
                    value={suggestedBy}
                    onChange={(event, value: IEmployee) => setSuggestedBy(value)}
                />
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={responsibleEmployee}
                    onChange={(event, value: IEmployee) => setResponsibleEmployee(value)}
                />
            </PaperRow>
            <PaperRow>
                <CustomCheckboxInput
                    onChange={() => setAddAction(!addAction)}
                    checked={addAction}
                    label={"Favorit wurde bereits kontaktiert"}
                />
            </PaperRow>
            {addAction &&
                <>
                    <div className={"flexContainerColumn marginLeft5"}>
                        <div className={"flexContainerRow marginLeft5"} style={{flexWrap: 'wrap'}}>
                            {addActions.map((actionValue: ESuggestionAction, index: number) => {
                                return (
                                    <div key={index} style={{width: 140}}>
                                        <CustomCheckboxInput
                                            checked={action === actionValue}
                                            onChange={() => setAction(actionValue)}
                                            label={translate('pages.activity.suggestionActionValueShort.' + actionValue)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.activity.properties.userInput')}
                            value={userInput}
                            multiline={true}
                            onChange={(value) => setUserInput(value)}
                            rows={10}
                        />
                    </PaperRow>
                </>
            }
        </div>
        <CustomDialogActions
            onClose={props.onClose}
            buttons={[{
                void: onSave,
                label: translate('misc.buttons.saveAndClose')
            }]}
        />
    </>;
};

export default AddSuggestionForVacancyForm;
