import React from 'react';
import {
    ECandidateSource,
    ESuggestionAction,
    ESuggestionStatus,
    ISuggestionListModel,
    ISuggestionProgressInformation,
} from '../../../definitions/entities.definition';
import CandidateSourceRenderer from '../../../components/CustomCellRenderer/CandidateSourceRenderer';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { Badge, Divider } from '@material-ui/core';
import { getFormattedDateString } from '../../../utils/date.utils';
import { translate } from '../../../translation/translate.utils';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import { useDispatch } from 'react-redux';

export const renderSuggestionContactAttempts = (progressInformation: ISuggestionProgressInformation) => {
    return <>
        <Divider orientation={"vertical"} style={{marginLeft: 10, height: 'auto'}}/>
        <div className={"marginBottom5"}>
            <div className={"formLabelSmall alignLeft marginTop5"}>Kontakt-Versuche ({progressInformation.contactCounts[ESuggestionAction.ACTION_MESSAGE_PORTAL]})</div>
            <div className={"marginLeft5 flexContainerRow flexWrap alignVerticalCenter gap20 marginTop10 marginBottom5"}>
                {Object.entries(progressInformation.contactCounts).map((contactWithCount) => {
                    const contactType = contactWithCount[0];
                    const contactCount = contactWithCount[1];

                    if (contactCount === 0 || contactType === ESuggestionAction.ACTION_MESSAGE_PORTAL) {
                        return null;
                    }

                    switch (contactType) {
                        case ESuggestionAction.ACTION_MESSAGE_MAIL:
                            return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.EMail)}</Badge></>;
                        case ESuggestionAction.ACTION_LETTER_SEND:
                            return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.Send)}</Badge></>;
                        case ESuggestionAction.ACTION_MESSAGE_MOBILE:
                            return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.SmartPhone)}</Badge></>;
                        case ESuggestionAction.ACTION_CALL_NOT_REACHED:
                            return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.PhoneDisabled)}</Badge></>;
                        default:
                            return <><Badge color={"error"} badgeContent={contactCount}><CandidateSourceRenderer source={contactType as ECandidateSource}/></Badge></>
                    }
                })}
            </div>
        </div>
    </>;
}

export const renderSuggestionProgressInformation = (suggestion: ISuggestionListModel) => {
    const dispatch = useDispatch();
    const progressInformation = suggestion.currentProgressInformation;

    if (suggestion.status === ESuggestionStatus.DECLINED) {
        return <>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn alignLeft"} style={{width: 200}}>
                <div className={"formLabelSmall"}>Absage {suggestion.declineDate && <>am {getFormattedDateString(suggestion.declineDate)}</> }</div>
                <div className={"formContent"}>
                    Grund: {translate('pages.vacancy.declineSuggestionReasonValues.' + suggestion.declineReason)}<br/>
                </div>
            </div>
        </>;
    }

    if (suggestion.callAppointmentAt) {
        return <>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn alignLeft cursorPointer"} onClick={() => dispatch(setUiEditView(EEntityView.suggestion, suggestion.id, 1))}>
                <div className={"formLabelSmall"}>Call</div>
                <div className={"formContent"}>am {getFormattedDateString(suggestion.callAppointmentAt)}</div>
            </div>
        </>;
    }

    if (progressInformation.isInCallCoordination) {
        return <>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn alignLeft cursorPointer"} onClick={() => dispatch(setUiEditView(EEntityView.suggestion, suggestion.id, 1))}>
                <div className={"formLabelSmall"}>Call-Koord.</div>
                <div className={"formContent"}>letzte Aktivität: {getFormattedDateString(suggestion.lastActivity)}</div>
            </div>
        </>;
    }

    if (suggestion.notReachableUntil) {
        return <>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn alignLeft cursorPointer"} onClick={() => dispatch(setUiEditView(EEntityView.suggestion, suggestion.id, 1))}>
                <div className={"formLabelSmall"}>Nicht erreichbar</div>
                <div className={"formContent"}>bis {getFormattedDateString(suggestion.notReachableUntil)}</div>
            </div>
        </>;
    }

    return null;
}

