import {
    IAssignLanguage,
    ICandidate,
    ICandidateForApi,
    ICandidateLanguage,
    ICandidateLanguageAssign,
    ICandidateListModel,
    ICandidatePartial,
    ICandidateSkillAssign,
} from '../definitions/entities.definition';
import { calculateAge } from './date.utils';
import {
    ICandidateFilterApiProperties,
    ICandidateFilterMenu,
    ICandidateForm,
} from '../definitions/candidatePage.definitions';

export const createApiObject = (
    candidateForm: ICandidateForm,
    fieldsIgnoredForValidation: string[],
    addRequest: boolean
): ICandidateForApi => {
    const assignSkills: ICandidateSkillAssign[] = candidateForm.skills.map((skill) => {
        return {
            id: skill.id,
            importantSkill: skill.importantSkill,
            skillId: skill.skill?.id,
            externalSkillId: skill.externalSkill?.id
        }
    });

    const assignLanguages: ICandidateLanguageAssign[] = candidateForm.languages.map((language) => {
        return {
            id: language.id,
            languageId: language.language.id,
            level: language.level
        }
    });

    return {
        topCandidate: candidateForm.topCandidate,
        topInCommunication: candidateForm.topInCommunication,
        moveWillingness: candidateForm.moveWillingness,
        typus: candidateForm.typus,
        proudOf: candidateForm.proudOf,
        comment: candidateForm.comment,
        thoughtsAboutTeamAndAppreciation: candidateForm.thoughtsAboutTeamAndAppreciation,
        flexibility: candidateForm.flexibility,
        responsibleId: candidateForm.responsible ? candidateForm.responsible.id : undefined,
        followupDate: candidateForm.followupDate,
        followupComment: candidateForm.followupComment,
        imagePath: candidateForm.imagePath,
        imageUrl: candidateForm.imageUrl,
        name: candidateForm.name || '',
        firstName: candidateForm.firstName || '',
        address: candidateForm.address || '',
        freelancer: candidateForm.freelancer,
        zipCode: candidateForm.zipCode || '',
        city: candidateForm.city || '',
        salutation: candidateForm.salutation,
        title: candidateForm.title,
        mailAddress: candidateForm.mailAddress || '',
        xingUrl: candidateForm.xingUrl || '',
        linkedInUrl: candidateForm.linkedInUrl || '',
        websiteUrl: candidateForm.websiteUrl || '',
        githubUrl: candidateForm.githubUrl || '',
        furtherUrl: candidateForm.furtherUrl || '',
        freelancerMapUrl: candidateForm.freelancerMapUrl || '',
        phoneNumber: candidateForm.phoneNumber || '',
        mobileNumber: candidateForm.mobileNumber || '',
        birthDate: candidateForm.birthDate || '',
        source: candidateForm.source,
        countryId: candidateForm.countryId || undefined,
        skills: assignSkills,
        languages: assignLanguages,
        salaryUnit: candidateForm.salaryUnit,
        expectedSalary: candidateForm.expectedSalary,
        expectedSalaryTo: candidateForm.expectedSalaryTo,
        homeOfficeDesire: candidateForm.homeOfficeDesire || undefined,
        noticePeriod: candidateForm.noticePeriod || undefined,
        leader: candidateForm.leader,
        desiredJobDescription: candidateForm.desiredJobDescription,
        currentJobDescription: candidateForm.currentJobDescription,
        hasDriverLicense: candidateForm.hasDriverLicense,
        wishDateOfStart: candidateForm.wishDateOfStart,
        partTimeFullTime: candidateForm.partTimeFullTime,
        applicationVacancyId: candidateForm.applicationVacancy ? candidateForm.applicationVacancy.id : undefined,
        topInPotential: candidateForm.topInPotential,
        linkWithSuggestionIds: candidateForm.linkWithSuggestionIds,
        numberOfEmployers: candidateForm.numberOfEmployers || '',
        fieldsIgnoredForValidation,
        yearsOfWorkExperience: (!addRequest || fieldsIgnoredForValidation.indexOf('yearsOfWorkExperience') > -1) ? candidateForm.yearsOfWorkExperience || undefined : candidateForm.yearsOfWorkExperience || '',
        level: (!addRequest || fieldsIgnoredForValidation.indexOf('level') > -1) ? candidateForm.level : candidateForm.level || '',
        educationLevel: (!addRequest || fieldsIgnoredForValidation.indexOf('educationLevel') > -1) ? candidateForm.educationLevel : candidateForm.educationLevel || '',
    };
}

export const getFilterByFilterMenu = (filterMenu: ICandidateFilterMenu): ICandidateFilterApiProperties => {
    return {
        salutation: filterMenu.salutation,
        skillIds: filterMenu.skills ? filterMenu.skills.map(({id}) => id) : [],
        optionalSkillIds: filterMenu.optionalSkills ? filterMenu.optionalSkills.map(({id}) => id) : [],
        languageIds: filterMenu.languages ? filterMenu.languages.map(({id}) => id) : [],
        leader: filterMenu.leader,
        yearsOfWorkExperience: filterMenu.yearsOfWorkExperience !== "0" ? filterMenu.yearsOfWorkExperience : undefined,
        distanceSearchCompanyLocationId: filterMenu.distanceSearchCompanyLocation?.id,
        distanceSearchKm: filterMenu.distanceSearchKm,
        ageFrom: filterMenu.ageFrom,
        ageTo: filterMenu.ageTo,
        topCandidate: filterMenu.topCandidate,
        topInCommunication: filterMenu.topInCommunication,
        responsibleEmployeeId: filterMenu.responsibleEmployee?.id,
        salaryFrom: filterMenu.salaryFrom,
        salaryTo: filterMenu.salaryTo,
        jobDescription: filterMenu.jobDescription,
        source: filterMenu.source,
        isFreelancer: filterMenu.isFreelancer,
        hasDriverLicense: filterMenu.hasDriverLicense,
        homeOfficeDesire: filterMenu.homeOfficeDesire,
        onlyFollowups: filterMenu.onlyFollowups,
        dateRangeFilter: filterMenu.dateRangeFilter,
        recruitingFilter: filterMenu.recruitingFilter ? {
            type: filterMenu.recruitingFilter.type,
            abortedAfter: filterMenu.recruitingFilter.abortedAfter,
            dateRange: filterMenu.recruitingFilter.dateRange,
            abortedBy: filterMenu.recruitingFilter.abortedBy,
            companyIds: filterMenu.recruitingFilter.companies ? filterMenu.recruitingFilter.companies.map((company) => company.id) : [],
            employeeIds: filterMenu.recruitingFilter.employees ? filterMenu.recruitingFilter.employees.map((employee) => employee.id) : [],
            finishedTrialPeriod: filterMenu.recruitingFilter.finishedTrialPeriod
        } : undefined
    }
}

export const candidateToListModel = (candidateModel: ICandidate, initialData?: ICandidateListModel): ICandidateListModel => {
    return {
        distanceText: '',
        durationText: '',
        matchedOptionalSkillIds: [],
        hasOpenSuggestion: false,
        hasOpenRecruiting: false,
        hasContactWithRecruiter: false,
        totalContracts: candidateModel.totalContracts,
        matchedSkills: [],
        candidateProfiles: {
            candidateId: candidateModel.id,
            xingUrl: candidateModel.xingUrl || undefined,
            linkedInUrl: candidateModel.linkedInUrl || undefined,
            homepageUrl: candidateModel.websiteUrl || undefined,
            freelancerMapUrl: candidateModel.freelancerMapUrl || undefined,
            furtherUrl: candidateModel.furtherUrl || undefined,
            githubUrl: candidateModel.githubUrl || undefined,
            cvId: candidateModel.latestDocumentCvId
        },
        ...initialData,
        age: candidateModel.birthDate ? calculateAge(candidateModel.birthDate) : undefined,
        id: candidateModel.id,
        name: candidateModel.name,
        country: candidateModel.country,
        firstName: candidateModel.firstName,
        address: candidateModel.address,
        zipCode: candidateModel.zipCode,
        city: candidateModel.city,
        salutation: candidateModel.salutation,
        topCandidate: candidateModel.topCandidate,
        topInCommunication: candidateModel.topInCommunication,
        birthDate: candidateModel.birthDate,
        skills: candidateModel.skills.length,
        lastActivity: candidateModel.lastActivity,
        latestDocumentCvId: undefined,
        sendBirthdayWishes: candidateModel.sendBirthdayWishes,
        desiredJobDescription: candidateModel.desiredJobDescription,
        currentJobDescription: candidateModel.currentJobDescription,
        source: candidateModel.source,
        responsible: candidateModel.responsible,
        xingUrl: candidateModel.xingUrl,
        linkedInUrl: candidateModel.linkedInUrl,
        freelancerMapUrl: candidateModel.freelancerMapUrl,
        followupDate: '',
        birthdayMailSend: false,
        fullName: candidateModel.fullName,
        hasContractRecruiting: initialData?.hasContractRecruiting || false,
        level: candidateModel.level,
        educationLevel: candidateModel.educationLevel,
        numberOfEmployers: candidateModel.numberOfEmployers,
        topInPotential: candidateModel.topInPotential,
        unattractive: candidateModel.unattractive
    }
}

export const onAddLanguagesToCandidate = (
    addLanguages: IAssignLanguage[],
    languages: ICandidateLanguage[],
    setLanguages: (languages: ICandidateLanguage[]) => void,
    candidate?: ICandidatePartial
) => {
    const addLanguages2: ICandidateLanguage[] = [];
    addLanguages.forEach((addLanguage) => {
        if (languages.find((candidateLanguage) => candidateLanguage.language.id === addLanguage.language.id) !== undefined) {
            return;
        }

        addLanguages2.push({
            id: 0,
            candidate,
            language: addLanguage.language,
            level: addLanguage.level
        });
    })

    setLanguages([
        ...languages,
        ...addLanguages2
    ].sort((a, b) => a.language.name.localeCompare(b.language.name)))
}
