import React, { useEffect, useState } from 'react';
import {
    ECandidateSource,
    ESuggestionStatus,
    ICandidatePartial,
    IEmployeePartial,
    IEntitySkill,
    IPluginCandidate,
    IPluginSkill,
    ISkill,
    ISkillPartial,
} from '../../../../definitions/entities.definition';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { translate } from '../../../../translation/translate.utils';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import {
    reopenSuggestionAction,
    updateSuggestionAndReloadListAction,
} from '../../../../redux/entities/entities.actions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getLoggedInEmployeeFromStore, userHasRole } from '../../../../selectors/employee.selectors';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { ROLE_ADMIN } from '../../../../definitions/actor.definitions';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import YearsOfWorkExperienceDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/YearsOfWorkExperienceDropDown';
import HomeOfficeDesireDropDown from '../../../../components/CustomInput/CustomDropDownInput/HomeOfficeDesireDropDown';
import PartTimeFullTimeDropDown from '../../../../components/CustomInput/CustomDropDownInput/PartTimeFullTimeDropDown';
import CustomNumberInput from '../../../../components/CustomInput/CustomNumberInput';
import CandidateSkillElement from '../../../EditViews/Candidate/components/components/CandidateSkillElement';
import NoticePeriodDropDown from '../../../../components/CustomInput/CustomDropDownInput/NoticePeriodDropDown';
import YesNoShowAllDropDown from '../../../../components/CustomInput/CustomDropDownInput/YesNoShowAllDropDown';
import { sortSkills } from '../../../../utils/skills.utils';
import SuggestionDeclineReasonDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/SuggestionDeclineReasonDropDown';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import { Chip, InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../../../components/CustomInput/CustomClickableIcon';
import { getSuggestionExternalUrl } from '../../../../utils/suggestion.utils';
import CandidateAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import CountryAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/CountryAutocomplete';
import VacancyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CandidateSourceRenderer from '../../../../components/CustomCellRenderer/CandidateSourceRenderer';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import moment from 'moment';
import { setVacancyPageSuggestionToDeleteAction } from '../../../../redux/vacancyPage/vacancyPage.actions';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';
import EmployeeAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import SuggestionChangeResponsibleView from './SuggestionChangeResponsibleView';
import { addOnBrainzPluginHandler, sendUrlToBrainzPlugin } from '../../../../utils/components.utils';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { registerError } from '../../../../redux/error/error.actions';
import { API_URL } from '../../../../requests/routes';

interface IProps {
    suggestionId: number;
}

const VacancySuggestionEditForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const suggestionId = props.suggestionId;
    const errors = useSelector(getValidationErrorsFromStore);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const initialSuggestion = useSelector((store: IStore) => store.entities.recruitingSuggestion.byId[suggestionId]);

    const [salutation, setSalutation] = useState(initialSuggestion?.salutation || 'mr');
    const [candidate, setCandidate] = useState<ICandidatePartial|null>(initialSuggestion?.candidate || null);
    const [name, setName] = useState(initialSuggestion?.name);
    const [firstName, setFirstName] = useState(initialSuggestion?.firstName);
    const [externalCandidateReference, setExternalCandidateReference] = useState(initialSuggestion?.externalCandidateReference || '');
    const [externalCandidateUrl, setExternalCandidateUrl] = useState(initialSuggestion?.externalCandidateUrl || '');
    const [suggestedBy, setSuggestedBy] = useState<IEmployeePartial | null>();
    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial | null>();
    const [phoneNumber, setPhoneNumber] = useState(initialSuggestion?.phoneNumber || '');
    const [email, setEmail] = useState(initialSuggestion?.mailAddress || '');
    const [xingUrl, setXingUrl] = useState(initialSuggestion?.xingUrl || initialSuggestion?.xingUrl || '');
    const [linkedInUrl, setLinkedInUrl] = useState(initialSuggestion?.linkedInUrl || initialSuggestion?.linkedInUrl || '');
    const [freelancerMapUrl, setFreelancerMapUrl] = useState(initialSuggestion?.freelancerMapUrl || initialSuggestion?.freelancerMapUrl || '');
    const [websiteUrl, setWebsiteUrl] = useState(initialSuggestion?.websiteUrl || initialSuggestion?.websiteUrl || '');
    const [githubUrl, setGithubUrl] = useState(initialSuggestion?.githubUrl || initialSuggestion?.githubUrl || '');
    const [furtherUrl, setFurtherUrl] = useState(initialSuggestion?.furtherUrl || initialSuggestion?.furtherUrl || '');
    const [vacancy, setVacancy] = useState(initialSuggestion?.vacancy);
    const disableEdit = false;
    const [zipCode, setZipCode] = useState(initialSuggestion?.zipCode || '');
    const [comment, setComment] = useState(initialSuggestion?.comment || '');
    const [address, setAddress] = useState(initialSuggestion?.address || '');
    const [declineReason, setDeclineReason] = useState(initialSuggestion?.declineReason);
    const [declineReasonComment, setDeclineReasonComment] = useState(initialSuggestion?.declineReasonComment || '');
    const [city, setCity] = useState(initialSuggestion?.city || '');
    const [country, setCountry] = useState(initialSuggestion?.country || null);
    const [skills, setSkills] = useState<IEntitySkill[]>(sortSkills(initialSuggestion?.skills || []));
    const [currentJobDescription, setCurrentJobDescription] = useState(initialSuggestion?.currentJobDescription || '');
    const [expectedSalary, setExpectedSalary] = useState(initialSuggestion?.expectedSalaryFrom || 0);
    const [expectedSalaryTo, setExpectedSalaryTo] = useState(initialSuggestion?.expectedSalaryTo || 0);
    const [homeOfficeDesire, setHomeOfficeDesire] = useState(initialSuggestion?.homeOfficeDesire || undefined);
    const [moveWillingness, setMoveWillingness] = useState(initialSuggestion?.moveWillingness || '');
    const [noticePeriod, setNoticePeriod] = useState(initialSuggestion?.noticePeriod);
    const [leader, setLeader] = useState(initialSuggestion?.leader || false);
    const [yearsOfWorkExperience, setYearsOfWorkExperience] = useState(initialSuggestion?.yearsOfWorkExperience);
    const [typus, setTypus] = useState(initialSuggestion?.typus || '');
    const [proudOf, setProudOf] = useState(initialSuggestion?.proudOf || '');
    const [thoughtsAboutTeamAndAppreciation, setThoughtsAboutTeamAndAppreciation] = useState(initialSuggestion?.thoughtsAboutTeamAndAppreciation || '');
    const [flexibility, setFlexibility] = useState(initialSuggestion?.flexibility || '');
    const [hasDriverLicense, setHasDriverLicense] = useState(initialSuggestion?.hasDriverLicense);
    const [wishDateOfStart, setWishDateOfStart] = useState(initialSuggestion?.wishDateOfStart?.substring(0, 10) || '');
    const [partTimeFullTime, setPartTimeFullTime] = useState(initialSuggestion?.partTimeFullTime);
    const [importInProgress, setImportInProgress] = useState(false);
    const [changedFieldsByImport, setChangedFieldsByImport] = useState<string[]>([]);
    const [responsibleChangedViewOpen, setResponsibleChangedViewOpen] = useState(false);
    const [responsibleChanged, setResponsibleChanged] = useState(false);
    const [responsibleChangedComment, setResponsibleChangedComment] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [externalSkills, setExternalSkills] = useState<IPluginSkill[]>([]);

    addOnBrainzPluginHandler((event: MessageEvent) => {
        setImportInProgress(false);

        if (!event.data.success) {
            dispatch(registerError('URL konnte nicht ausgelesen werden  '));
            return;
        }

        const importData: IPluginCandidate = event.data.data;
        const changedFields = [];

        if (!firstName && importData.firstName) {
            setFirstName(importData.firstName);
            changedFields.push('firstName');
        }

        if (!name && importData.lastName) {
            setName(importData.lastName);
            changedFields.push('name');
        }

        if (!currentJobDescription && importData.currentJobDescription) {
            setCurrentJobDescription(importData.currentJobDescription);
            changedFields.push('currentJobDescription');
        }

        if (!email && importData.mailAddress) {
            setEmail(importData.mailAddress);
            changedFields.push('mailAddress');
        }

        if (!furtherUrl && importData.websiteUrl) {
            setFurtherUrl(importData.websiteUrl);
            changedFields.push('furtherUrl');
        }

        if (!city && importData.city) {
            setCity(importData.city.replace(', Deutschland', '').replace('Metropolregion ', ''));
            changedFields.push('city');
        }

        if (!initialSuggestion.imagePath && importData.imageUrl) {
            setImageUrl(importData.imageUrl);
        }

        setExternalSkills(importData.skills.filter((externalSkill) => {
           const skillExists = skills.find((skill) => skill.externalSkill && skill.externalSkill.name === externalSkill.skill);
           return !skillExists;
        }));

        setChangedFieldsByImport(changedFields);
    });

    useEffect(() => {
        setChangedFieldsByImport([]);
        setCandidate(initialSuggestion.candidate || null);
        setSalutation(initialSuggestion.salutation);
        setName(initialSuggestion.name);
        setFirstName(initialSuggestion.firstName);
        setExternalCandidateReference(initialSuggestion.externalCandidateReference);
        setExternalCandidateUrl(initialSuggestion.externalCandidateUrl);
        setSuggestedBy(initialSuggestion.suggestedByEmployee);
        setResponsibleEmployee(initialSuggestion.responsibleEmployee);
        setPhoneNumber(initialSuggestion.phoneNumber);
        setEmail(initialSuggestion.mailAddress);
        setVacancy(initialSuggestion.vacancy);
        setXingUrl(initialSuggestion.xingUrl);
        setLinkedInUrl(initialSuggestion.linkedInUrl);
        setFreelancerMapUrl(initialSuggestion.freelancerMapUrl);
        setWebsiteUrl(initialSuggestion.websiteUrl);
        setGithubUrl(initialSuggestion.githubUrl);
        setFurtherUrl(initialSuggestion.furtherUrl);
        setZipCode(initialSuggestion.zipCode);
        setComment(initialSuggestion.comment);
        setAddress(initialSuggestion.address);
        setDeclineReason(initialSuggestion.declineReason);
        setDeclineReasonComment(initialSuggestion.declineReasonComment);
        setCity(initialSuggestion.city);
        setCountry(initialSuggestion.country);
        setSkills(initialSuggestion.skills);
        setCurrentJobDescription(initialSuggestion.currentJobDescription);
        setExpectedSalary(initialSuggestion.expectedSalaryFrom);
        setExpectedSalaryTo(initialSuggestion.expectedSalaryTo);
        setHomeOfficeDesire(initialSuggestion.homeOfficeDesire);
        setMoveWillingness(initialSuggestion.moveWillingness);
        setNoticePeriod(initialSuggestion.noticePeriod);
        setLeader(initialSuggestion.leader);
        setYearsOfWorkExperience(initialSuggestion.yearsOfWorkExperience);
        setTypus(initialSuggestion.typus);
        setProudOf(initialSuggestion.proudOf);
        setThoughtsAboutTeamAndAppreciation(initialSuggestion.thoughtsAboutTeamAndAppreciation);
        setFlexibility(initialSuggestion.flexibility);
        setHasDriverLicense(initialSuggestion.hasDriverLicense);
        setWishDateOfStart(initialSuggestion.wishDateOfStart?.substring(0, 10) || '');
        setPartTimeFullTime(initialSuggestion.partTimeFullTime);
    }, [initialSuggestion]);

    useEffect(() => {
        if (!responsibleEmployee) {
            return;
        }

        if (initialSuggestion.responsibleEmployee.id !== responsibleEmployee.id && responsibleEmployee.id !== loggedInEmployee.id) {
            setResponsibleChangedViewOpen(true);
        }
    }, [responsibleEmployee]);

    const onRemoveSkill = (skill: ISkill | ISkillPartial) => {
        setSkills(skills.filter((candidateSkill: IEntitySkill) => skill.id !== candidateSkill.skill.id));
    };

    const onSetTopSkill = (skill: ISkill | ISkillPartial) => {
        setSkills(skills.map((candidateSkill: IEntitySkill) => {
            if (candidateSkill.skill.id === skill.id) {
                return {
                    ...candidateSkill,
                    importantSkill: !candidateSkill.importantSkill,
                };
            }
            return candidateSkill;
        }));
    };

    const onSave = () => {
        dispatch(updateSuggestionAndReloadListAction(token, {
            id: initialSuggestion.id,
            vacancyId: vacancy?.id,
            salutation,
            name,
            firstName,
            externalCandidateUrl,
            externalCandidateReference,
            candidateId: candidate ? candidate.id : null,
            phoneNumber,
            mailAddress: email,
            address,
            linkedInUrl,
            freelancerMapUrl,
            websiteUrl,
            githubUrl,
            furtherUrl,
            xingUrl,
            suggestedByEmployeeId: suggestedBy ? suggestedBy.id : undefined,
            responsibleEmployeeId: responsibleEmployee ? responsibleEmployee.id : undefined,
            responsibleChanged,
            responsibleChangedComment,
            zipCode,
            city,
            comment,
            countryId: country ? country.id : undefined,
            //@ts-ignore
            skills: skills.map((skill: IEntitySkill) => {
                return {
                    id: skill.id,
                    importantSkill: skill.importantSkill,
                    skillId: skill.skill?.id,
                    externalSkillId: skill.externalSkill?.id,
                };
            }),
            currentJobDescription,
            expectedSalaryFrom: expectedSalary,
            expectedSalaryTo,
            homeOfficeDesire,
            moveWillingness,
            noticePeriod,
            leader,
            yearsOfWorkExperience,
            typus,
            proudOf,
            thoughtsAboutTeamAndAppreciation,
            flexibility,
            hasDriverLicense,
            wishDateOfStart,
            partTimeFullTime,
            declineReason,
            declineReasonComment,
            imageUrl,
            externalSkills
        }));
    };

    const externalUrl = getSuggestionExternalUrl(initialSuggestion.source, externalCandidateUrl, externalCandidateReference);
    const userIsAdmin = userHasRole(ROLE_ADMIN);

    return <>
        <CustomValidationErrors translationKey={'pages.recruiting'}/>
        <div style={{width: '100%', overflow: 'auto'}}>
            <SuggestionChangeResponsibleView
                onClose={() => {
                    setResponsibleChanged(false);
                    setResponsibleChangedViewOpen(false);
                }}
                onSave={(comment) => {
                    setResponsibleChanged(true);
                    setResponsibleChangedComment(comment);
                }}
                open={responsibleChangedViewOpen}
                newResponsibleName={responsibleEmployee?.fullName}
            />
            <CustomBackdropLoadingInsideDiv open={importInProgress}/>
            {imageUrl &&
                <PaperRow>
                    <div className={"flexContainerColumn flex1 alignVerticalCenter alignHorizontalCenter"}>
                        <h4 style={{margin: 5}}>Bild</h4>
                        <img src={imageUrl} height={200}/>
                    </div>
                </PaperRow>
            }
            {(initialSuggestion.imagePath || initialSuggestion.candidateProfiles.photoId) &&
                <PaperRow>
                    <div className={"flexContainerColumn flex1 alignVerticalCenter alignHorizontalCenter"}>
                        <h4 style={{margin: 5}}>Bild</h4>
                        {initialSuggestion.candidate
                            ? <img
                                height={150}
                                src={API_URL + "/candidate-image/" + initialSuggestion.candidateProfiles.photoId + "/preview?Authorization=" + token}
                            />
                            : <img
                                height={150}
                                src={API_URL + "/recruiting-suggestion/" + initialSuggestion.id + "/image?Authorization=" + token}
                            />
                        }
                    </div>
                </PaperRow>
            }
            <PaperRow>
                <div className={"flexContainerColumn flexVerticalCentered"}>
                    <CandidateSourceRenderer size={"medium"} source={initialSuggestion.source}/>
                </div>
                <SalutationDropDown
                    // @ts-ignore-next-line
                    onChange={(event: any) => setSalutation(event.target.value)}
                    value={salutation}
                    required={true}
                    disabled={disableEdit}
                />
                <CustomTextField
                    value={firstName}
                    onChange={(value) => setFirstName(value)}
                    label={changedFieldsByImport.includes('firstName') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('pages.candidate.properties.firstName')}</b> : translate('pages.candidate.properties.firstName')}
                    disabled={disableEdit}
                />
                <CustomTextField
                    value={name}
                    onChange={(value) => setName(value)}
                    label={changedFieldsByImport.includes('name') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('pages.candidate.properties.name')}</b> : translate('pages.candidate.properties.name')}
                    disabled={disableEdit}
                />
                {initialSuggestion.source === ECandidateSource.jobcenter &&
                <CustomTextField
                    label={translate('pages.vacancy.externalCandidateReference')}
                    value={externalCandidateReference}
                    onChange={(value) => setExternalCandidateReference(value)}
                    disabled={disableEdit}
                    error={Boolean(errors.externalCandidateReference)}
                    helperText={errors.externalCandidateReference}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CustomClickableIcon onClick={() => window.open(externalUrl)} icon={EIcons.Launch}/>
                            </InputAdornment>
                        ),
                    }}
                />
                }
            </PaperRow>
            <PaperRow>
                <CustomUrlField
                    value={xingUrl}
                    onChange={(value) => setXingUrl(value)}
                    label={'Xing Url'}
                    error={Boolean(errors.xingUrl)}
                    helperText={errors.xingUrl}
                />
                <CustomUrlField
                    value={linkedInUrl}
                    onChange={(value) => setLinkedInUrl(value)}
                    label={'linkedIn Url'}
                    error={Boolean(errors.linkedInUrl)}
                    helperText={errors.linkedInUrl}
                    importFunction={document.getElementById('brainz_import_url') ? () => {
                        setImportInProgress(true);
                        sendUrlToBrainzPlugin(linkedInUrl)
                    } : undefined}
                />
                <CustomUrlField
                    value={freelancerMapUrl}
                    onChange={(value) => setFreelancerMapUrl(value)}
                    label={'Freelancermap Url'}
                    error={Boolean(errors.freelancerMapUrl)}
                    helperText={errors.freelancerMapUrl}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    value={phoneNumber}
                    disabled={disableEdit}
                    onChange={(value) => setPhoneNumber(value)}
                    label={translate('misc.mobileNumber')}
                />
                <CustomTextField
                    value={email}
                    disabled={disableEdit}
                    onChange={(value) => setEmail(value)}
                    label={changedFieldsByImport.includes('mailAddress') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('misc.email')}</b> : translate('misc.email')}
                />
                <CustomUrlField
                    value={websiteUrl}
                    onChange={(value) => setWebsiteUrl(value)}
                    label={'Homepage Url'}
                    error={Boolean(errors.websiteUrl)}
                    helperText={errors.websiteUrl}
                />
            </PaperRow>
            <PaperRow>
                <CustomUrlField
                    value={githubUrl}
                    onChange={(value) => setGithubUrl(value)}
                    label={'Github Url'}
                />
                <CustomUrlField
                    value={furtherUrl}
                    onChange={(value) => setFurtherUrl(value)}
                    label={changedFieldsByImport.includes('furtherUrl') ? <b style={{color: 'var(--brainz-color-green)'}}>Weitere Url</b> : 'Weitere Url'}
                />
            </PaperRow>
            <PaperRow>
                <VacancyAutocomplete
                    label={translate('misc.vacancy')}
                    value={vacancy}
                    required
                    onChange={(value) => setVacancy(value)}
                    disabled={disableEdit}
                />
                <CandidateAutocomplete
                    value={candidate}
                    onChange={(value) => {
                        setCandidate(value);
                    }}
                    disabled={disableEdit}
                />
            </PaperRow>
            <PaperRow>
                <EmployeeAutocomplete
                    label={translate('pages.vacancy.suggestedByEmployee')}
                    value={suggestedBy || null}
                    required
                    disabled={disableEdit || initialSuggestion && !userIsAdmin && initialSuggestion.suggestedByEmployee.id !== loggedInEmployee.id}
                    onChange={( value) => setSuggestedBy(value)}
                />
                <EmployeeAutocomplete
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={responsibleEmployee || null}
                    required
                    onChange={(value) => setResponsibleEmployee(value)}
                    disabled={disableEdit}
                />
            </PaperRow>
            {initialSuggestion?.status === ESuggestionStatus.DECLINED &&
            <>
                <PaperRow>
                    <SuggestionDeclineReasonDropDown
                        value={declineReason}
                        onChange={(value) => setDeclineReason(value)}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={'Kommentar zur Absage'}
                        value={declineReasonComment}
                        onChange={(value) => setDeclineReasonComment(value)}
                        multiline={true}
                        rows={4}
                    />
                </PaperRow>
            </>
            }
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={10}
                    value={comment}
                    onChange={(value) => setComment(value)}
                    label={translate('misc.comment')}
                />
            </PaperRow>
            <>
                <PaperRow>
                    <CustomTextField
                        value={zipCode}
                        onChange={(value) => setZipCode(value)}
                        label={translate('misc.zipCode')}
                        error={Boolean(errors.zipCode)}
                        helperText={errors.zipCode}
                    />
                    <CustomTextField
                        value={city}
                        onChange={(value) => setCity(value)}
                        label={translate('misc.city')}
                        error={Boolean(errors.city)}
                        helperText={errors.city}
                    />
                    <CustomTextField
                        value={address}
                        onChange={(value) => setAddress(value)}
                        label={translate('misc.address')}
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                    />
                    <CountryAutocomplete
                        value={country}
                        error={Boolean(errors.countryId)}
                        helperText={errors.countryId}
                        onChange={(country) => setCountry(country)}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={changedFieldsByImport.includes('currentJobDescription') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('pages.candidate.properties.currentJobDescription')}</b> : translate('pages.candidate.properties.currentJobDescription')}
                        value={currentJobDescription}
                        onChange={(value) => setCurrentJobDescription(value)}
                    />
                    <CustomCheckboxInput
                        labelTranslation={'pages.candidate.properties.leader'}
                        checked={leader}
                        onChange={() => setLeader(!leader)}
                    />
                </PaperRow>
                <PaperRow>
                    <YearsOfWorkExperienceDropDown
                        onChange={(event) => setYearsOfWorkExperience(event.target.value)}
                        // @ts-ignore-next-line
                        value={yearsOfWorkExperience}
                    />
                    <HomeOfficeDesireDropDown
                        onChange={(value) => setHomeOfficeDesire(value)}
                        // @ts-ignore-next-line
                        value={homeOfficeDesire}
                    />
                    <PartTimeFullTimeDropDown
                        onChange={(value) =>
                            // @ts-ignore-next-line
                            setPartTimeFullTime(value)
                        }
                        // @ts-ignore-next-line
                        value={partTimeFullTime}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomNumberInput
                        value={expectedSalary}
                        onChange={(value) => setExpectedSalary(value)}
                        label={translate('pages.candidate.salaryFrom')}
                        max={999}
                    />
                    <CustomNumberInput
                        value={expectedSalaryTo}
                        onChange={(value) => setExpectedSalaryTo(value)}
                        label={translate('pages.candidate.salaryTo')}
                        max={999}
                    />
                </PaperRow>
                <PaperRow>
                    <h4 style={{margin: 5}}>Skills ({skills.length})</h4>
                </PaperRow>
                <PaperRow>
                    {skills.map((candidateSkill, index) =>
                        <CandidateSkillElement
                            key={index}
                            skill={candidateSkill}
                            onRemove={onRemoveSkill}
                            onSetTop={onSetTopSkill}
                        />,
                    )}
                    {(externalSkills.length > 0) &&
                    <div className={"flexContainerColumn flex1"}>
                        <h4 style={{margin: 5}}>Skills importieren ({externalSkills.length})</h4>
                        <div className={"flexContainerRow gap5 flexWrap"}>
                            {externalSkills.map((externalSkill) => <Chip
                                key={externalSkill.skill}
                                color={'primary'}
                                label={<div className={"flex flexCentered"}>
                                    <img width={22} src={"/linkedIn.png"} title={"Aus LinkedIn"}/>&nbsp;
                                    {externalSkill.skill}
                                </div>}
                            />)}
                        </div>
                    </div>
                    }
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        flex={4}
                        value={typus}
                        label={translate('pages.candidate.properties.typus')}
                        onChange={(value) => setTypus(value)}
                    />
                </PaperRow>
                <PaperRow>
                    <NoticePeriodDropDown
                        onChange={(event) => setNoticePeriod(event.target.value)}
                        // @ts-ignore-next-line
                        value={noticePeriod}
                    />
                    <CustomDatePicker
                        required
                        label={translate('pages.candidate.properties.wishDateOfStart')}
                        value={wishDateOfStart}
                        onChange={(value) => {
                            setWishDateOfStart(value ? moment(value).format("Y-MM-DD") : undefined)
                        }}
                    />
                    <YesNoShowAllDropDown
                        allText={translate('misc.choose')}
                        onChange={(value) => setHasDriverLicense(value)}
                        value={hasDriverLicense}
                        label={translate('pages.candidate.properties.hasDriverLicense')}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        value={proudOf}
                        multiline
                        rows={3}
                        label={translate('pages.candidate.properties.proudOf')}
                        onChange={(value) => setProudOf(value)}
                    />
                    <CustomTextField
                        value={thoughtsAboutTeamAndAppreciation}
                        rows={3}
                        multiline
                        label={translate('pages.candidate.properties.thoughtsAboutTeamAndAppreciation')}
                        onChange={(value) => setThoughtsAboutTeamAndAppreciation(value)}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        value={flexibility}
                        rows={3}
                        multiline
                        label={translate('pages.candidate.properties.flexibility')}
                        onChange={(value) => setFlexibility(value)}
                    />
                    <CustomTextField
                        multiline
                        rows={3}
                        value={moveWillingness}
                        onChange={(value) => setMoveWillingness(value)}
                        label={translate('pages.candidate.properties.moveWillingness')}
                    />
                </PaperRow>
            </>
        </div>
        <CustomDialogActions
            buttons={[{
                void: () => {
                    dispatch(setVacancyPageSuggestionToDeleteAction(suggestionId));
                },
                hidden: initialSuggestion.status === ESuggestionStatus.ACCEPTED,
                label: 'Löschen',
            }, {
                void: () => {
                    dispatch(reopenSuggestionAction(
                        token,
                        suggestionId,
                    ));
                },
                hidden: initialSuggestion.status !== ESuggestionStatus.DECLINED,
                label: 'Vorschlag wieder öffnen',
            }, {
                void: onSave,
                label: 'Speichern',
            }]}
        />
    </>;
};

export default VacancySuggestionEditForm;
