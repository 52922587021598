import { IApiBaseFilter, ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';
import {
    ICandidateFilterApiProperties,
    ICandidateFilterMenu,
    preloadedCandidatePageFilter,
    preloaededCandidatePageFilterMenu,
} from './candidatePage.definitions';
import { IAddSuggestionViewState, ISuggestionFilter, ISuggestionFilterMenu } from './recruitingPage.definitions';
import { ESuggestionStatus } from './entities.definition';

export const defaultSuggestionOfVacancyFilter: ISuggestionFilter = {
    start: 0,
    limit: 0,
    status: ESuggestionStatus.OPEN
}

export const preloadedStateVacancyPage: IVacancyPageState = {
    activityFilter: {
        start: 0,
        limit: 50
    },
    addSuggestionView: false,
    initialOpenVacanciesLoaded: false,
    initialSuggestionsLoaded: false,
    editViewLoading: false,
    reloadVacancy: false,
    vacancyListSorting: {
        'pin': ESortingOptions.Ascending
    },
    vacancyListFilter: {
        start: 0,
        limit: 50,
        responsibleEmployeeId: 0
    },
    suggestionSorting: {
        ranking: ESortingOptions.Ascending
    },
    applicationSorting: {
        created: ESortingOptions.Descending
    },
    recruitingSorting: {
        created: ESortingOptions.Descending
    },
    vacancyToArchive: 0,
    vacancyToDelete: 0,
    addVacancyDialogOpen: false,
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    suggestionActivitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    suggestionActivityFilter: {
        start: 0,
        limit: 0
    },
    suggestionEditViewLoading: false,
    activityToDelete: 0,
    suggestionToDelete: 0,
    suggestionToAddAction: 0,
    suggestionToDecline: {
        id: 0,
        followup: false
    },
    suggestionToEdit: 0,
    reloadSuggestions: false,
    reloadRecruitingsOfVacancy: false,
    copyVacancyId: 0,
    filterCandidateList: preloadedCandidatePageFilter,
    filterMenuCandidateList: preloaededCandidatePageFilterMenu,
    sortingCandidateList: {},
    suggestionListViewOpen: false,
    suggestionListViewFilter: {
        start: 0,
        limit: 50
    },
    suggestionListViewFilterMenu: {},
    suggestionListViewSorting: {
        ranking: ESortingOptions.Ascending
    },
    suggestionOfVacancyFilter: defaultSuggestionOfVacancyFilter,
    suggestionOfVacancyFilterMenu: {},
    suggestionOfVacancySorting: {
        'ranking': ESortingOptions.Ascending
    },
    suggestionsCollapsedOrExpanded: []
};

export interface IVacancyPageState {
    addSuggestionView: IAddSuggestionViewState;
    initialOpenVacanciesLoaded: boolean;
    initialSuggestionsLoaded: boolean;
    editViewLoading: boolean;
    reloadVacancy: boolean,
    suggestionEditViewLoading: boolean;
    vacancyListSorting: ISorting;
    vacancyListFilter: IVacancyFilter;
    suggestionSorting: ISorting;
    applicationSorting: ISorting;
    recruitingSorting: ISorting;
    vacancyToArchive: number;
    vacancyToDelete: number;
    addVacancyDialogOpen: boolean;
    addVacancyDialogForCompanyId?: number;
    activitySorting: ISorting;
    activityFilter: IApiBaseFilter;
    suggestionActivitySorting: ISorting;
    suggestionActivityFilter: IApiBaseFilter;
    activityToDelete: number;
    suggestionToAddAction: number;
    suggestionToDecline: {
        id: number;
        followup: boolean
    };
    suggestionToDelete: number;
    suggestionToEdit: number;
    reloadSuggestions: boolean;
    reloadRecruitingsOfVacancy: boolean;
    copyVacancyId: number;
    sortingCandidateList: ISorting;
    filterCandidateList: ICandidateFilterApiProperties,
    filterMenuCandidateList: ICandidateFilterMenu,
    suggestionListViewSorting: ISorting;
    suggestionListViewOpen: boolean;
    suggestionListViewFilter: ISuggestionFilter;
    suggestionListViewFilterMenu: ISuggestionFilterMenu;
    suggestionOfVacancyFilter: ISuggestionFilter;
    suggestionOfVacancyFilterMenu: ISuggestionFilterMenu;
    suggestionOfVacancySorting: ISorting;
    suggestionsCollapsedOrExpanded: {[vacancyId: number]: number[]}
}

export interface IVacancyFilter extends Partial<IApiBaseFilter> {
    responsibleEmployeeId?: number;
}
