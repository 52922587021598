import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    resetCandidatePageFieldsForIgnoreValidation,
    setCandidatePageCvToImportAction,
} from '../../../redux/candidatePage/candidatePage.actions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { resetFormValidation } from '../../../redux/error/error.actions';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import { ICandidateLanguage, ICandidateSkill } from '../../../definitions/entities.definition';
import CandidateProfileDataPanel from '../../EditViews/Candidate/components/CandidateProfileDataPanel';
import { API_URL } from '../../../requests/routes';
import CustomDashboardWidgetPaper from '../../../components/CustomPaper/CustomDashboardWidgetPaper';
import { getToken } from '../../../selectors/app.selectors';
import { ICandidateForm, initialCandidateFormState } from '../../../definitions/candidatePage.definitions';
import { addCandidateAction } from '../../../redux/entities/entities.actions';
import { createApiObject, onAddLanguagesToCandidate } from '../../../utils/candidate.utils';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { useCreateCandidateHook } from './CandidatAddView.hooks';

const CandidateImportCvView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => store.candidatePage.importFromCvParser !== undefined);
    const cvImportCandidate = useSelector((store: IStore) => store.candidatePage.importFromCvParser);
    const cvImportFile = useSelector((store: IStore) => store.candidatePage.importCvFile);
    const editViewLoading = useSelector((store: IStore) => store.candidatePage.editViewLoading);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const fieldsIgnoredForValidation = useSelector((store: IStore) => store.candidatePage.formFieldsIgnoredForValidation);

    const [candidateForm, setCandidateForm] = useState<ICandidateForm>({
        ...initialCandidateFormState,
        responsible: loggedInEmployee
    });
    const {matchingSuggestions, duplicateCandidates} = useCreateCandidateHook(candidateForm.name, candidateForm.firstName);

    const setCandidateFormPartial = (candidateFormChange: Partial<ICandidateForm>) => {
        setCandidateForm({
            ...candidateForm,
            ...candidateFormChange
        })
    }

    const onSave = () => {
        dispatch(addCandidateAction(token, {
            ...createApiObject(candidateForm, fieldsIgnoredForValidation, true),
            //@ts-ignore
            importCvName: cvImportFile.file.name,
            //@ts-ignore
            importCvPath: cvImportCandidate.file
        }));
    }

    const onClose = () => {
        dispatch(resetFormValidation());
        dispatch(setCandidatePageCvToImportAction(undefined, undefined));
    };

    useEffect(() => {
        dispatch(resetCandidatePageFieldsForIgnoreValidation());
        dispatch(resetFormValidation());
        setCandidateForm({
            ...initialCandidateFormState,
            responsible: loggedInEmployee
        });
    }, [open]);

    useEffect(() => {
        if (!cvImportCandidate) {
            return;
        }

        const skills: ICandidateSkill[] = [];
        cvImportCandidate.skills.forEach((skill) => {
            skills.push({
                skill,
                identifier: "candidate",
                id: - (skill.id),
                count: 0,
                importantSkill: false,
                addedByParser: true
            });
        });

        const setLanguages = (languages: ICandidateLanguage[]) => setCandidateForm({
            ...candidateForm,
            name: cvImportCandidate.name || '',
            firstName: cvImportCandidate.firstName || '',
            address: cvImportCandidate.address || '',
            zipCode: cvImportCandidate.zipCode || '',
            city: cvImportCandidate.city || '',
            salutation: cvImportCandidate.salutation || 'mr',
            mailAddress: cvImportCandidate.mailAddress || '',
            xingUrl: cvImportCandidate.xingUrl || '',
            linkedInUrl: cvImportCandidate.linkedInUrl || '',
            mobileNumber: cvImportCandidate.mobilePhoneNumber || '',
            phoneNumber: cvImportCandidate.phoneNumber || '',
            birthDate: cvImportCandidate.birthDate || '',
            imagePath: cvImportCandidate.imagePath || '',
            languages,
            skills
        })
        onAddLanguagesToCandidate(cvImportCandidate.languages.map((languageToAdd) => { return {
            language: languageToAdd.language,
            level: languageToAdd.level
            //@ts-ignore
        }}), [], setLanguages, candidateForm);
    }, [cvImportCandidate])

    if (!open) {
        return null;
    }

    return (
        <CustomDialog fullWidth open={open} onClose={onClose}>
            <CustomBackdropLoading open={editViewLoading} />
            <CustomDialogHeader translationString="pages.candidate.add" onClose={onClose} />
            <div className={"flex padding5 gap10"}>
                <div className={"flex gap10"}>
                    <div className={"flexContainerColumn flex2"} style={{maxWidth: 800}}>
                        <CustomDashboardWidgetPaper design={"light"} headline={"Dokumente"}>
                            <iframe
                                style={{margin: 5, height: '100%', border: '1px solid var(--brainz-darkest)'}}
                                frameBorder="0"
                                src={API_URL + "/parsed-cv/preview?file=" + cvImportCandidate?.file + "&Authorization=" + token}
                            />
                        </CustomDashboardWidgetPaper>
                    </div>
                    <div className={"flexContainerColumn gap10 flex1 overflow"} style={{minWidth: 600}}>
                        <CandidateProfileDataPanel
                            addView
                            duplicateCandidates={duplicateCandidates}
                            matchingSuggestions={matchingSuggestions}
                            candidate={candidateForm}
                            setCandidate={setCandidateFormPartial}
                        />
                    </div>
                </div>
            </div>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: onSave,
                    label: translate('misc.buttons.create')
                }]}
            />
        </CustomDialog>
    );
};

export default CandidateImportCvView;
